import React, { useContext, useState, useEffect } from "react";
import { ThemePreferenceContext, themesMap } from "../../../../index";
import "./Settings.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Divider, TextField, InputAdornment } from "@mui/material";
import AuthContext from "../../../../hooks/useAuth";
import axios from "../../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import currencies from "./commonCurrency.json";

function Settings() {
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const auth = useContext(AuthContext);
  const role = auth.role;
  const currentTimestamp = new Date().toISOString();
  const initialValue = {
    pb_id: "1",
    org_id: "13",
    pb_name: "",
    effective_from: "",
    effective_to: "",
    currency: "",
    created_at: currentTimestamp,
    created_by: "13",
    pricing_category_id: "1",
    pricing_category_name: "Basic",
    free_usage_per_month: "",
    unit_type: "",
    unit_price: "",
    min_guarantee_unit: "",
    unit_price_post_guarantee_unit: "",
    due_date_threshold: "30",
  };
  const [priceData, setPriceData] = React.useState(initialValue);
  const [priceBook, setPriceBook] = React.useState(false);
  const [openPrice, setOpenPrice] = React.useState(false);
  const [editPrice, setEditPrice] = React.useState(false);
  const [openDel, setOpenDel] = React.useState(false);
  const [updateId, setUpdateId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currencyFrom, setCurrencyFrom] = useState("");
  const handleSelectFrom = (event) => {
    const { value } = event.target;
    setCurrencyFrom(value);
  };
  const handlePriceOpen = () => {
    setOpenPrice(true);
  };

  const handlePriceClose = () => {
    setOpenPrice(false);
    setPriceData(initialValue);
    setSelectedCategory("");
    setCurrencyFrom("");
  };

  const handleEditOpen = () => {
    setEditPrice(true);
  };

  const handleEditClose = () => {
    setEditPrice(false);
    setSelectedCategory("");
    // setPriceData(initialValue);
  };

  const handleClickDelOpen = () => {
    setOpenDel(true);
  };

  const handleDelClose = () => {
    setOpenDel(false);
  };

  useEffect(() => {
    getPriceBook();
  }, []);

  const getPriceBook = () => {
    axios
      .get(`/bookpricing`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        // params: {
        //   id: updateId,
        // },
      })
      .then((res) => {
        setPriceBook(res.data.data);
      });
  };
  // const handlePriceClose = () => {
  //   setOpenPrice(false);
  //   setPriceData(initialValue);
  // };

  const handleFormAddSubmit = async () => {
    const orgId = "13";
    const values = JSON.stringify(priceData);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        params: {
          id: orgId,
        },
      };
      await axios.post(`/bookpricing/${orgId}`, values, config);
      // await axios.post(`/bookpricing/${orgId}`, values, config);
      // await axios.post(`/countrydetails`, values, config);

      handlePriceClose();
      getPriceBook();
      // toast.success("User Added Successfully !", {
      //   position: toast.POSITION.BOTTOM_CENTER,
      // });
    } catch (error) {
      handlePriceClose();
      // toast.error("Enter all credentails !", {
      //   position: toast.POSITION.BOTTOM_CENTER,
      // });
    }
  };
  const onChange = (e) => {
    const { id, value } = e.target;
    setPriceData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  return (
    <>
      {(role === "admin" || role === "tester") && (
        <div style={{ marginTop: "4rem" }}>
          <div
            style={{
              fontSize: 18,
              fontWeight: "600",
              color: currentTheme === "dark" ? "#FFFFFF" : "",
            }}
          >
            Level up your desktop experience with just one click - Get the App
            Now!{" "}
            <span style={{ color: currentTheme === "dark" ? "yellow" : "red" }}>
              (upcoming*)
            </span>
          </div>
          <div
            style={{
              marginTop: "5%",
              fontSize: 15,
              fontWeight: "600",
              color: currentTheme === "dark" ? "#FFFFFF" : "",
            }}
          >
            FOR WINDOWS
            <span style={{ marginLeft: "2%" }}>
              <button
                className="beautiful-button"
                onClick={() =>
                  (window.location.href =
                    "https://gamemon-desktop-builds.s3.ap-south-1.amazonaws.com/GameMon+Setup+0.1.16.exe")
                }
              >
                Click here!
              </button>
            </span>
          </div>
          <div
            style={{
              marginTop: "5%",
              fontSize: 15,
              fontWeight: "600",
              color: currentTheme === "dark" ? "#FFFFFF" : "",
            }}
          >
            FOR LINUX
            <span style={{ marginLeft: "2%" }}>
              <button
                className="beautiful-button"
                onClick={() =>
                  (window.location.href =
                    "https://gamemon-desktop-builds.s3.ap-south-1.amazonaws.com/GameMon-0.1.11.AppImage")
                }
              >
                Click here!
              </button>
            </span>
          </div>
          <div
            style={{
              marginTop: "5%",
              fontSize: 15,
              fontWeight: "600",
              color: currentTheme === "dark" ? "#FFFFFF" : "",
            }}
          >
            FOR MAC
            {/* <span style={{ marginLeft: "2%" }}>
              <button
                className="beautiful-button"
                onClick={() =>
                  (window.location.href =
                    "https://gamemon-desktop-builds.s3.ap-south-1.amazonaws.com/GameMon-0.1.11.AppImage")
                }
              >
                Click here!
              </button>
            </span> */}
            <span
              style={{
                fontSize: 18,
                color: currentTheme === "dark" ? "yellow" : "red",
              }}
            >
              *
            </span>
          </div>
          <div
            style={{
              marginTop: "5%",
              fontSize: 15,
              fontWeight: "600",
              color: currentTheme === "dark" ? "#FFFFFF" : "",
            }}
          >
            Installation Guide
            <span style={{ marginLeft: "2%" }}>
              <button
                className="beautiful-button"
                onClick={() =>
                  (window.location.href =
                    "https://gamemon-desktop-builds.s3.ap-south-1.amazonaws.com/GameMon+(1)+4.docx")
                }
              >
                Click here!
              </button>
            </span>
            {/* <span style={{ marginLeft: "2%" }}>
                {" "}
                <button
                  className="beautiful-button"
                >
                  Check out!
                </button>
              </span> */}
          </div>
        </div>
      )}
      {role !== "admin" && role !== "tester" && (
        <div style={{ marginTop: "4rem" }}>
          <div
            style={{
              marginTop: "5%",
              fontSize: "16px",
              fontWeight: "600",
              marginLeft: "1%",
              color: currentTheme === "dark" ? "#FFFFFF" : "",
              width: "500px",
            }}
          >
            Pricing Book{" "}
            <span>
              <span style={{ marginLeft: "2%" }}>
                <button
                  className="beautiful-button"
                  style={{
                    width: "80px",
                    borderRadius: "5px",
                    background: "#03AC13",
                  }}
                  onClick={() => handlePriceOpen()}
                >
                  ADD
                </button>
              </span>
              <span style={{ marginLeft: "2%" }}>
                <button
                  className="beautiful-button"
                  style={{
                    width: "80px",
                    borderRadius: "5px",
                    background: "#278EF1",
                  }}
                  onClick={handleEditOpen}
                >
                  EDIT
                </button>
              </span>
              <span style={{ marginLeft: "2%" }}>
                <button
                  className="beautiful-button"
                  style={{
                    width: "80px",
                    borderRadius: "5px",
                    background: "red",
                  }}
                  onClick={handleClickDelOpen}
                >
                  DELETE
                </button>
              </span>
            </span>
          </div>
          <div style={{ marginTop: "2%" }}>
            <div
              style={{
                display: "flex",
                overflowX: "auto",
                width: "70vw",
                // padding: "20px 0",
                marginLeft: "5%",
                // background:"red",
                justifyContent: "center",
                marginTop: "1.5%",
              }}
            >
              {/* adding dailog */}
              <Dialog
                PaperProps={{
                  style: {
                    boxShadow: "none",
                    // maxWidth: "xl",
                    minWidth: selectedCategory === "type-2" ? "900px" :"800px",
                    // width: "40vw",
                    minHeight: selectedCategory === "type-2" ? "570px" : "500px",
                    backgroundColor:
                      currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                      overflow:"hidden"
                  },
                }}
                open={openPrice}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <DialogTitle
                    style={{
                      fontSize: "20px",
                      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                    }}
                    // id="alert-dialog-title"
                  >
                    Add Pricing Book
                  </DialogTitle>
                  <CancelIcon
                    onClick={handlePriceClose}
                    style={{
                      marginTop: "2%",
                      cursor: "pointer",
                      marginRight: "3vh",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                    }}
                  />
                </div>
                <Divider
                  style={{
                    margin: "0vw 1.5vw",
                    width: "93%",
                    backgroundColor: "grey",
                  }}
                />
                <DialogContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    // marginTop:"-5%"
                  }}
                >
                  <form style={{overflow:"hidden"}}>
                    {/* First Row */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "20px", // Add margin to separate from the next row
                      }}
                    >
                      {/* Field 1 */}
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Pricing Book Name:
                            <span style={{ color: "red" }}>*</span>
                          </span>
                          <br />
                          <TextField
                            id="pb_name"
                            value={priceData?.pb_name}
                            onChange={(e) => onChange(e)}
                            InputProps={{
                              placeholder: "Name",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                      </div>
                      {/* Field 2 */}

                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Currency:
                            <span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <FormControl
                            style={{
                              width: "220px",
                              marginTop: "2%",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                          >
                            <Select style={{ height: "45px", color: "#818E94" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxWidth: "220px",
                                    color:
                                      currentTheme === "dark"
                                        ? "#818E94"
                                        : "black",
                                    background:
                                      currentTheme === "dark"
                                        ? "#292E33"
                                        : "#FFFFFF",
                                        height:"200px",
                                  },
                                },
                              }}onChange={handleSelectFrom} value={currencyFrom}>
              {Object.keys(currencies).map((currency, index) => (
                <MenuItem value={currency} key={index}>
                  {currency}({currencies[currency].symbol}) - {currencies[currency].name}
                </MenuItem>
              ))}
            </Select>
                          </FormControl>
                        </Typography>
                      </div>
                      {/* Field 3 */}
                      <div style={{ flex: 1, marginLeft: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Unit Type:<span style={{ color: "red" }}>
                              *
                            </span>{" "}
                          </span>
                          <br />
                          <FormControl
                            style={{
                              width: "220px",
                              marginTop: "2%",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                          >
                            <Select
                              name="unit_type"
                              // value={role}
                              // onChange={onRoleChange}
                              style={{ height: "45px", color: "#818E94" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    minWidth: "220px",
                                    color:
                                      currentTheme === "dark"
                                        ? "#818E94"
                                        : "black",
                                    background:
                                      currentTheme === "dark"
                                        ? "#292E33"
                                        : "#FFFFFF",
                                  },
                                },
                              }}
                            >
                              {/* <MenuItem value="">Type</MenuItem> */}
                              <MenuItem value="hours">Hours</MenuItem>
                              <MenuItem value="minutes">Minutes</MenuItem>
                              {/* <MenuItem value="super_admin">Super Admin</MenuItem> */}
                            </Select>
                          </FormControl>
                        </Typography>
                      </div>
                    </div>
                    <br />
                    {/* second row */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "-3%",
                      }}
                    >
                      {/* Field 4 */}
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Effective From:
                            <span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <TextField
                            // style={{
                            //   borderRadius: "5px",
                            //   width: "22.5vw",
                            //   marginTop: "2%",
                            //   backgroundColor:
                            //     currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            // }}
                            id="effective_from"
                            type="date"
                            // label="End Date"
                            value={priceData?.effective_from}
                            onChange={(e) => onChange(e)}
                            variant="outlined"
                            style={{
                              width: "220px",
                              marginTop: "2%",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                padding: 8.5,
                                color:
                                  currentTheme === "dark" ? "#818E94" : "black",
                                  height:"28px"
                              },
                            }}
                            margin="dense"
                          />
                        </Typography>
                      </div>
                      {/* Field 5 */}
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Pricing Category:
                            <span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <FormControl
                            style={{
                              width: "220px",
                              marginTop: "2%",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                          >
                            <Select
                             name="category_type"
                             value={selectedCategory}
                             onChange={(e) => setSelectedCategory(e.target.value)}
                              style={{ height: "45px", color: "#818E94" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    minWidth: "220px",
                                    color:
                                      currentTheme === "dark"
                                        ? "#818E94"
                                        : "black",
                                    background:
                                      currentTheme === "dark"
                                        ? "#292E33"
                                        : "#FFFFFF",
                                  },
                                },
                              }}
                            >
                              {/* <MenuItem value="">Type</MenuItem> */}
                              <MenuItem value="type-1">Flat Pricing</MenuItem>
                              <MenuItem value="type-2">Tiered Pricing</MenuItem>
                              {/* <MenuItem value="super_admin">Super Admin</MenuItem> */}
                            </Select>
                          </FormControl>
                        </Typography>
                      </div>
                      {/* Field 6 */}
                      <div style={{ flex: 1, marginLeft: "10px" }}></div>
                    </div>
                    <br />
                    {/* third Row */}
                    {/* <Typography>selectedCategory === "type-2"</Typography> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // marginTop: "-3%"
                      }}
                    >
                      {/* Field 4 */}
                      <div style={{ flex: 1, marginRight: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Free Usage:<span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <TextField
                            id="free_usage_per_month"
                            value={priceData?.free_usage_per_month}
                            onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Hours",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                      </div>
                      {selectedCategory === "type-2" && (
                        <div style={{display:"flex",flexDirection:'row',}}>
                      <div style={{ flex: 1, marginRight: "10px", marginLeft:"-560px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Tier 1 Threshold:<span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <TextField
                            // id="free_usage_per_month"
                            // value={priceData?.free_usage_per_month}
                            // onChange={(e) => onChange(e)}
                            InputProps={{
                              placeholder: "Hours",
                              type:"number",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      >
                         <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Unit Price:<span style={{ color: "red" }}>
                              *
                            </span>{" "}
                          </span>
                          <br />
                          <TextField
                            id="unit_price"
                            value={priceData?.unit_price}
                            onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Cost",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                        
                      </div>
                      </div>
                      )}
                      {/* Field 2 */}
                      {selectedCategory !=="type-2" && (
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "-240px",
                        }}
                      >
                         <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Unit Price:<span style={{ color: "red" }}>
                              *
                            </span>{" "}
                          </span>
                          <br />
                          <TextField
                            id="unit_price"
                            value={priceData?.unit_price}
                            onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Cost",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                        
                      </div>
                      )}
                      {/* Field 3 */}
                      {/* <div style={{ flex: 1, marginLeft: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Currency:<span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <TextField
                            id="currency"
                            value={priceData?.currency}
                            onChange={(e) => onChange(e)}
                            InputProps={{
                              placeholder: "Type",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                      </div> */}
                    </div>
                    <br />
                    {/* forth Row */}
                    {selectedCategory === "type-2" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // marginTop: "-3%"
                      }}
                    >
                      {/* Field 4 */}
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <div
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Unit Price:<span style={{ color: "red" }}>*</span>{" "}<span
                            style={{
                              color: "#fcba03",
                               fontSize:"12px",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Applicable Beyond (Tier 1): <span>{" "} </span>
                          </span>
                          </div>
                          {/* <br /> */}
                          <TextField
                            // id="free_usage_per_month"
                            // value={priceData?.free_usage_per_month}
                            // onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Cost",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                        {/* <Typography>
                          <span
                            style={{
                              color: "#fcba03",
                              fontSize:"10px",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Applicable Beyond (Tier 1): <span>{" "} </span>
                           
                          </span>
                          <br />
                        </Typography> */}
                      </div>
                      {/* Field 2 */}
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "10px",
                          // marginTop:"3%"
                        }}
                      >
                       
                      </div>
                      {/* Field 3 */}
                      <div style={{ flex: 1, marginLeft: "10px" }}></div>
                    </div>
                    )}
                  </form>
                  <div style={{ flex: 1, marginTop:selectedCategory === "type-2"? "-2%":"1%" }}>
                    <DialogActions
                      style={{
                        display: "flex",
                        // marginTop: "3%",
                        // margin: "0vw 5vw",
                        justifyContent: "flex-end",
                      }}
                    >
                      <List
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ListItem
                          style={{
                            color: "#4779EF",
                            // backgroundColor: "#4779EF",
                            borderRadius: "6px",
                            border: "2px solid #4779EF",
                            width: "12vw",
                            height: "40px",
                            fontSize: "15px",
                            justifyContent: "center",
                            // marginBottom: "10px",
                            // marginLeft: "-20px",
                            fontWeight: "600",
                            fontFamily:
                              "normal normal bold 16px/21px Product Sans",
                          }}
                          button
                          onClick={() => {
                            // handleFormAddSubmit();
                            handlePriceClose();
                          }}
                          variant="outlined"
                          required
                          color="secondary"
                        >
                          Back
                        </ListItem>
                        <ListItem
                          style={{
                            color: "#FFFFFF",
                            backgroundColor: "#4779EF",
                            borderRadius: "10px",
                            width: "12vw",
                            height: "40px",
                            fontSize: "15px",
                            justifyContent: "center",
                            marginRight: "10px",
                            marginLeft: "20px",
                            fontWeight: "600",
                            fontFamily:
                              "normal normal bold 16px/21px Product Sans",
                          }}
                          button
                          onClick={() => {
                            handleFormAddSubmit();
                            handlePriceClose();
                          }}
                        >
                          Create
                        </ListItem>
                      </List>
                    </DialogActions>
                  </div>
                </DialogContent>
              </Dialog>
              {/* editing dailog */}
              <Dialog
                PaperProps={{
                  style: {
                    boxShadow: "none",
                    // maxWidth: "xl",
                    minWidth: selectedCategory === "type-2" ? "900px" :"800px",
                    // width: "40vw",
                    minHeight: selectedCategory === "type-2" ? "570px" : "500px",
                    backgroundColor:
                      currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                      overflow:"hidden"
                  },
                }}
                open={editPrice}
                onClose={handleEditClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <DialogTitle
                    style={{
                      fontSize: "20px",
                      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                    }}
                    // id="alert-dialog-title"
                  >
                    Edit Pricing Book
                  </DialogTitle>
                  <CancelIcon
                   onClick={handleEditClose}
                    style={{
                      marginTop: "2%",
                      cursor: "pointer",
                      marginRight: "3vh",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                    }}
                  />
                </div>
                <Divider
                  style={{
                    margin: "0vw 1.5vw",
                    width: "93%",
                    backgroundColor: "grey",
                  }}
                />
                 <DialogContent
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    // marginTop:"-5%"
                  }}
                >
                  <form style={{overflow:"hidden"}}>
                    {/* First Row */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginBottom: "20px", // Add margin to separate from the next row
                      }}
                    >
                      {/* Field 1 */}
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Pricing Book Name:
                            <span style={{ color: "red" }}>*</span>
                          </span>
                          <br />
                          <TextField
                            id="pb_name"
                            value={priceData?.pb_name}
                            onChange={(e) => onChange(e)}
                            InputProps={{
                              placeholder: "Name",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                      </div>
                      {/* Field 2 */}

                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Currency:
                            <span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <FormControl
                            style={{
                              width: "220px",
                              marginTop: "2%",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                          >
                            <Select
                            //  name="category_type"
                            //  value={selectedCategory}
                            //  onChange={(e) => setSelectedCategory(e.target.value)}
                              style={{ height: "45px", color: "#818E94" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    minWidth: "220px",
                                    color:
                                      currentTheme === "dark"
                                        ? "#818E94"
                                        : "black",
                                    background:
                                      currentTheme === "dark"
                                        ? "#292E33"
                                        : "#FFFFFF",
                                  },
                                },
                              }}
                            >
                              {/* <MenuItem value="">Type</MenuItem> */}
                              <MenuItem value="dollar">Dollar</MenuItem>
                              <MenuItem value="inr">INR</MenuItem>
                              {/* <MenuItem value="super_admin">Super Admin</MenuItem> */}
                            </Select>
                          </FormControl>
                        </Typography>
                      </div>
                      {/* Field 3 */}
                      <div style={{ flex: 1, marginLeft: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Unit Type:<span style={{ color: "red" }}>
                              *
                            </span>{" "}
                          </span>
                          <br />
                          <FormControl
                            style={{
                              width: "220px",
                              marginTop: "2%",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                          >
                            <Select
                              name="unit_type"
                              // value={role}
                              // onChange={onRoleChange}
                              style={{ height: "45px", color: "#818E94" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    minWidth: "220px",
                                    color:
                                      currentTheme === "dark"
                                        ? "#818E94"
                                        : "black",
                                    background:
                                      currentTheme === "dark"
                                        ? "#292E33"
                                        : "#FFFFFF",
                                  },
                                },
                              }}
                            >
                              {/* <MenuItem value="">Type</MenuItem> */}
                              <MenuItem value="hours">Hours</MenuItem>
                              <MenuItem value="minutes">Minutes</MenuItem>
                              {/* <MenuItem value="super_admin">Super Admin</MenuItem> */}
                            </Select>
                          </FormControl>
                        </Typography>
                      </div>
                    </div>
                    <br />
                    {/* second row */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "-3%",
                      }}
                    >
                      {/* Field 4 */}
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Effective From:
                            <span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <TextField
                            // style={{
                            //   borderRadius: "5px",
                            //   width: "22.5vw",
                            //   marginTop: "2%",
                            //   backgroundColor:
                            //     currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            // }}
                            id="effective_from"
                            type="date"
                            // label="End Date"
                            value={priceData?.effective_from}
                            onChange={(e) => onChange(e)}
                            variant="outlined"
                            style={{
                              width: "220px",
                              marginTop: "2%",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                padding: 8.5,
                                color:
                                  currentTheme === "dark" ? "#818E94" : "black",
                                  height:"28px"
                              },
                            }}
                            margin="dense"
                          />
                        </Typography>
                      </div>
                      {/* Field 5 */}
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Pricing Category:
                            <span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <FormControl
                            style={{
                              width: "220px",
                              marginTop: "2%",
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                          >
                            <Select
                             name="category_type"
                             value={selectedCategory}
                             onChange={(e) => setSelectedCategory(e.target.value)}
                              style={{ height: "45px", color: "#818E94" }}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    minWidth: "220px",
                                    color:
                                      currentTheme === "dark"
                                        ? "#818E94"
                                        : "black",
                                    background:
                                      currentTheme === "dark"
                                        ? "#292E33"
                                        : "#FFFFFF",
                                  },
                                },
                              }}
                            >
                              {/* <MenuItem value="">Type</MenuItem> */}
                              <MenuItem value="type-1">Flat Pricing</MenuItem>
                              <MenuItem value="type-2">Tiered Pricing</MenuItem>
                              {/* <MenuItem value="super_admin">Super Admin</MenuItem> */}
                            </Select>
                          </FormControl>
                        </Typography>
                      </div>
                      {/* Field 6 */}
                      <div style={{ flex: 1, marginLeft: "10px" }}></div>
                    </div>
                    <br />
                    {/* third Row */}
                    {/* <Typography>selectedCategory === "type-2"</Typography> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // marginTop: "-3%"
                      }}
                    >
                      {/* Field 4 */}
                      <div style={{ flex: 1, marginRight: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Free Usage:<span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <TextField
                            id="free_usage_per_month"
                            value={priceData?.free_usage_per_month}
                            onChange={(e) => onChange(e)}
                            InputProps={{
                              placeholder: "Hours",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                      </div>
                      {selectedCategory === "type-2" && (
                        <div style={{display:"flex",flexDirection:'row',}}>
                      <div style={{ flex: 1, marginRight: "10px", marginLeft:"-560px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Tier 1 Threshold:<span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <TextField
                            // id="free_usage_per_month"
                            // value={priceData?.free_usage_per_month}
                            // onChange={(e) => onChange(e)}
                            InputProps={{
                              placeholder: "Hours",
                              type:"number",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                      </div>
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "10px",
                        }}
                      >
                         <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Unit Price:<span style={{ color: "red" }}>
                              *
                            </span>{" "}
                          </span>
                          <br />
                          <TextField
                            id="unit_price"
                            value={priceData?.unit_price}
                            onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Cost",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                        
                      </div>
                      </div>
                      )}
                      {/* Field 2 */}
                      {selectedCategory !=="type-2" && (
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "-240px",
                        }}
                      >
                         <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Unit Price:<span style={{ color: "red" }}>
                              *
                            </span>{" "}
                          </span>
                          <br />
                          <TextField
                            id="unit_price"
                            value={priceData?.unit_price}
                            onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Cost",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                        
                      </div>
                      )}
                      {/* Field 3 */}
                      {/* <div style={{ flex: 1, marginLeft: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Currency:<span style={{ color: "red" }}>*</span>{" "}
                          </span>
                          <br />
                          <TextField
                            id="currency"
                            value={priceData?.currency}
                            onChange={(e) => onChange(e)}
                            InputProps={{
                              placeholder: "Type",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                      </div> */}
                    </div>
                    <br />
                    {/* forth Row */}
                    {selectedCategory === "type-2" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // marginTop: "-3%"
                      }}
                    >
                      {/* Field 4 */}
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                        <div
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Unit Price:<span style={{ color: "red" }}>*</span>{" "}<span
                            style={{
                              color: "#fcba03",
                               fontSize:"12px",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Applicable Beyond (Tier 1): <span>{" "} </span>
                          </span>
                          </div>
                          {/* <br /> */}
                          <TextField
                            id="free_usage_per_month"
                            value={priceData?.free_usage_per_month}
                            onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Cost",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width:"190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                        {/* <Typography>
                          <span
                            style={{
                              color: "#fcba03",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Applicable Beyond (Tier 1)*: <span>{" "} </span>
                           
                          </span>
                          <br />
                        </Typography> */}
                      </div>
                      {/* Field 2 */}
                    
                      {/* Field 3 */}
                      <div style={{ flex: 1, marginLeft: "10px" }}></div>
                    </div>
                    )}
                  </form>
                  <div style={{ flex: 1, marginTop:selectedCategory === "type-2"? "-2%":"1%" }}>
                    <DialogActions
                      style={{
                        display: "flex",
                        // marginTop: "3%",
                        // margin: "0vw 5vw",
                        justifyContent: "flex-end",
                      }}
                    >
                      <List
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ListItem
                          style={{
                            color: "#4779EF",
                            // backgroundColor: "#4779EF",
                            borderRadius: "6px",
                            border: "2px solid #4779EF",
                            width: "12vw",
                            height: "40px",
                            fontSize: "15px",
                            justifyContent: "center",
                            // marginBottom: "10px",
                            // marginLeft: "-20px",
                            fontWeight: "600",
                            fontFamily:
                              "normal normal bold 16px/21px Product Sans",
                          }}
                          button
                          onClick={() => {
                            // handleFormAddSubmit();
                           handleEditClose();
                          }}
                          variant="outlined"
                          required
                          color="secondary"
                        >
                          Back
                        </ListItem>
                        <ListItem
                          style={{
                            color: "#FFFFFF",
                            backgroundColor: "#4779EF",
                            borderRadius: "10px",
                            width: "12vw",
                            height: "40px",
                            fontSize: "15px",
                            justifyContent: "center",
                            marginRight: "10px",
                            marginLeft: "20px",
                            fontWeight: "600",
                            fontFamily:
                              "normal normal bold 16px/21px Product Sans",
                          }}
                          button
                          onClick={() => {
                            handleEditClose();
                          }}
                        >
                          Update
                        </ListItem>
                      </List>
                    </DialogActions>
                  </div>
                </DialogContent>
              </Dialog>
             
              {/* delete dailog */}
              <Dialog
                PaperProps={{
                  sx: {
                    width: "450px",
                    height: "250px",
                    backgroundColor:
                      currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                  },
                }}
                open={openDel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <DialogTitle
                    style={{
                      fontSize: "20px",
                      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                    }}
                    // id="alert-dialog-title"
                  >
                    Delete Pricing Book
                  </DialogTitle>
                  <CancelIcon
                    onClick={handleDelClose}
                    style={{
                      marginTop: "4.3%",
                      cursor: "pointer",
                      marginRight: "2.5vh",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                    }}
                  />
                </div>
                <Divider
                  style={{
                    margin: "0vw 1.5vw",
                    width: "85%",
                    backgroundColor: "grey",
                  }}
                />
                <DialogContent>
                  {/* <div
                    style={{ flex: 1, marginRight: "10px", marginTop: "-10px" }}
                  >
                    <Typography style={{ color: "#818E94" }}>
                      <span
                        style={{
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                        }}
                      >
                        Select the Pricing Book:
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <br />
                      <Select
                        InputProps={{
                          placeholder: "Name",
                          inputProps: {
                            style: {
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              textTransform: "capitalize",
                              height: "10px",
                            },
                          },
                        }}
                        // label="Password"
                        variant="outlined"
                        required
                        style={{
                          backgroundColor:
                            currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                          borderRadius: "5px",
                          width: "250px",
                          height: "42px",
                          marginTop: "4%",
                        }}
                        margin="dense"
                      ></Select>
                    </Typography>
                  </div> */}
                  {/* <br /> */}
                  <h4 style={{ color: "grey",marginTop:"1%" }}>
                    Are you sure, you want to delete the Pricing Book?
                  </h4>
                  <DialogActions
                    style={{
                      marginLeft: "1vw",
                      display: "flex",
                      justifyContent: "row",
                      marginRight: "5vw",
                      marginTop: "1.5%",
                      // marginBottom:'1vh',
                      position: "fixed",
                      // gap: "20px",
                    }}
                  >
                    <List>
                      <div
                        style={{
                          color: "#03AC13",
                          marginTop: "7%",
                          border: "2px solid #03AC13",
                          boxShadow: "white",
                          borderRadius: "8px",
                          width: "160px",
                          height: "40px",
                          padding: "7px",
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem
                          button
                          onClick={handleDelClose}
                          style={{
                            paddingLeft: "30%",
                            marginTop: "-4%",
                            fontFamily:
                              "normal normal bold 16px/21px Product Sans",
                          }}
                          color="secondary"
                          variant="outlined"
                        >
                          Cancel
                        </ListItem>
                      </div>
                    </List>
                    <List>
                      <div
                        style={{
                          color: "#FFFFFF",
                          marginTop: "8%",
                          background: "red",
                          borderRadius: "8px",
                          width: "160px",
                          height: "40px",
                          padding: "3px",
                          fontSize: "15px",
                          marginLeft: "20px",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem
                          button
                          // onClick={handleDelete}
                          style={{
                            paddingLeft: "30%",
                            // marginTop: "4%",
                            fontFamily:
                              "normal normal bold 16px/21px Product Sans",
                          }}
                          color="secondary"
                          variant="outlined"
                        >
                          Proceed
                        </ListItem>
                      </div>
                    </List>
                  </DialogActions>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Settings;
