import React, { useContext, useState, useEffect } from "react";
import { ThemePreferenceContext, themesMap } from "../../../../index";
import "./Settings.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Divider, TextField, InputAdornment } from "@mui/material";
import AuthContext from "../../../../hooks/useAuth";
import axios from "../../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function EditPricingBook({
  open,
  handleClose,
  data,
  onChange,
  onEmail,
  handleFormSubmit,
  onChangeCurrency,
  onChangeUnitType,
  onChangeCategoryType,
  onEffectiveDateChange,
  onDateChange,
  pricingData,
  updateId,
  updatePbId,
  onChange3,
  onChange2,
}) {
  const {
    pb_id,
    org_id,
    pb_name,
    effective_from,
    currency,
    created_by,
    pricing_category_id,
    pricing_category_name,
    free_usage_per_month,
    unit_type,
    unit_price,
    min_guarantee_unit,
    unit_price_post_guarantee_unit,
    due_date_threshold,
  } = data;

  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const auth = useContext(AuthContext);
  const role = auth.role;
  const currentTimestamp = new Date().toISOString();
  const [priceBook, setPriceBook] = React.useState(false);
  // const [updateId, setUpdateId] = useState("");
  // const [categoryType, setSelectedCategory] = useState("");
  const [currencyFrom, setCurrencyFrom] = useState("");


  const [unitType, setUnitType] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [effectiveFrom, setEffectiveFrom] = useState('');
  const handleChange = (event) => {
    // console.log("before");
    setUnitType(event.target.value);
    // console.log("after", event.target.value);
    onChangeUnitType(event.target.value);
  };
  const handleChange2 = (event) => {
    // console.log("before cate");
    setCategoryType(event.target.value);
    // console.log("after cate", event.target.value);
    onChangeCategoryType(event.target.value);
  };
  // const handleChange3 = (event) => {
  //   console.log("before date");
  //   setEffectiveFrom(event.target.value);
  //   console.log("after date", event.target.value);
  //   onEffectiveDateChange(event.target.value);
  // };

  const handleCurrency = (event) => {
    // const { value } = event.target;
    setCurrencyFrom(event.target.value);
    onChangeCurrency(event.target.value);
  };

  const [currencies, SetCurrencies] = useState([]);
  useEffect(() => {
    axios
      .get("/currencydetails", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const sortedCurrencies = res.data.data.sort((a, b) =>
          a.currency_description.localeCompare(b.currency_description)
        );
        SetCurrencies(sortedCurrencies);
      });
  }, []);

  const [category, setCategory] = useState([]);

  useEffect(() => {
    axios
      .get("/pricingcategory", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCategory(res.data.data);
      });
  }, []);

  useEffect(() => {
    getPriceBook();
  }, []);

  const getPriceBook = () => {
    axios
      .get(`/bookpricing`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        // params: {
        //   id: updateId,
        // },
      })
      .then((res) => {
        setPriceBook(res.data.data);
      });
  };

  const handleUpdate = () => {
    // if (validateForm1()) {
    handleClose();
    handleFormSubmit();
    setUnitType();
    setCategoryType();
  }

  const formatDate = (isoDate) => {
    if (!isoDate) return ''; 
  
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); 
    const day = ('0' + date.getDate()).slice(-2);
  
    return `${year}-${month}-${day}`;
  };
  


  return (
    <>

      <div style={{ marginTop: "4rem" }}>
        <div style={{ marginTop: "2%" }}>
          <div
            style={{
              display: "flex",
              overflowX: "auto",
              width: "70vw",
              // padding: "20px 0",
              marginLeft: "5%",
              // background:"red",
              justifyContent: "center",
              marginTop: "1.5%",
            }}
          >
            {/* editing dailog */}
            <Dialog
              PaperProps={{
                style: {
                  boxShadow: "none",
                  // maxWidth: "xl",
                  minWidth: pricing_category_name === "Tiered Pricing" ? "900px" : "800px",
                  // width: "40vw",
                  minHeight: pricing_category_name === "Tiered Pricing" ? "570px" : "500px",
                  backgroundColor:
                    currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                  overflow: "hidden"
                },
              }}
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <DialogTitle
                  style={{
                    fontSize: "20px",
                    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                    color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                  }}
                // id="alert-dialog-title"
                >
                  Edit Pricing Book
                </DialogTitle>
                <CancelIcon
                  onClick={handleClose}
                  style={{
                    marginTop: "2%",
                    cursor: "pointer",
                    marginRight: "3vh",
                    color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                  }}
                />
              </div>
              <Divider
                style={{
                  margin: "0vw 1.5vw",
                  width: "93%",
                  backgroundColor: "grey",
                }}
              />
              <DialogContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  // marginTop:"-5%"
                }}
              >
                <form style={{ overflow: "hidden" }}>
                  {/* First Row */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "20px", // Add margin to separate from the next row
                    }}
                  >
                    {/* Field 1 */}
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          Pricing Book Name:
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <br />
                        <TextField
                          id="pb_name"
                          value={pb_name}
                          onChange={(e) => onChange(e)}
                          InputProps={{
                            placeholder: "Name",
                            inputProps: {
                              style: {
                                color:
                                  currentTheme === "dark"
                                    ? "#FFFFFF"
                                    : "#000000",
                                textTransform: "capitalize",
                                height: "10px",
                              },
                            },
                          }}
                          // label="Password"
                          variant="outlined"
                          required
                          style={{
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          margin="dense"
                        ></TextField>
                      </Typography>
                    </div>
                    {/* Field 2 */}

                    <div
                      style={{
                        flex: 1,
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          {" "}
                          Currency:
                          <span style={{ color: "red" }}>*</span>{" "}
                        </span>
                        <br />
                        <FormControl
                          style={{
                            width: "220px",
                            marginTop: "2%",
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <Select style={{ height: "45px",  color:
                        currency ? (currency === "Currency" ? "#818E94" : currentTheme === "dark" ? "#FFFFFF" : "#000000") : "#818E94",}}
                            id="currency"
                            onChange={handleCurrency}
                            value={currency}
                            displayEmpty
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxWidth: "220px",
                                  color:
                                    currentTheme === "dark"
                                      ? "#818E94"
                                      : "black",
                                  background:
                                    currentTheme === "dark"
                                      ? "#292E33"
                                      : "#FFFFFF",
                                  height: "200px",
                                },
                                className:"ScrollBars"
                              },
                            }}>
                            {currencies.map((currency) => (
                              <MenuItem
                                key={currency.currency_id}
                                value={`${currency.currency_symbol.replace(/:.*/, '')}`}
                                style={{
                                  color: "#818E94",
                                  fontSize: "14px",
                                  height: "30px",
                                }}
                              >
                                {`${currency.currency_description} (${currency.currency_symbol.replace(/:.*/, '')})`}
                              </MenuItem>
                            ))}


                          </Select>
                        </FormControl>
                      </Typography>
                    </div>
                    {/* Field 3 */}
                    <div style={{ flex: 1, marginLeft: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          {" "}
                          Unit Type:<span style={{ color: "red" }}>
                            *
                          </span>{" "}
                        </span>
                        <br />
                        <FormControl
                          style={{
                            width: "220px",
                            marginTop: "2%",
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <Select
                            id="unit_type"
                            value={unit_type}
                            onChange={handleChange}
                            style={{ height: "45px",  color:
                            unit_type ? (unit_type === "Currency" ? "#818E94" : currentTheme === "dark" ? "#FFFFFF" : "#000000") : "#818E94",}}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  minWidth: "220px",
                                  color:
                                    currentTheme === "dark"
                                      ? "#818E94"
                                      : "black",
                                  background:
                                    currentTheme === "dark"
                                      ? "#292E33"
                                      : "#FFFFFF",
                                },
                              },
                            }}
                          >
                           <MenuItem value="Hour"style={{
                                  color: "#818E94",
                                  // fontSize: "16px",
                                  height: "30px",
                                }}>Hours</MenuItem>
                            <MenuItem value="Minute"style={{
                                  color: "#818E94",
                                  // fontSize: "14px",
                                  height: "30px",
                                }}>Minutes</MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </div>
                  </div>
                  <br />
                  {/* second row */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "-3%",
                    }}
                  >
                    {/* Field 4 */}
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          {" "}
                          Effective From:
                          <span style={{ color: "red" }}>*</span>{" "}
                        </span>
                        <br />
                        <TextField
  id="effective_from"
  type="date"
  value={formatDate(effective_from)}
  onChange={onEffectiveDateChange}
  variant="outlined"
  style={{
    width: "220px",
    marginTop: "2%",
    backgroundColor: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
    borderRadius: "5px",
  }}
  InputLabelProps={{
    shrink: true,
  }}
  inputProps={{
    style: {
      padding: 8.5,
      color: currentTheme === "dark" ? "#818E94" : "black",
      height: "28px"
    },
  }}
  margin="dense"
/>
                      </Typography>
                    </div>
                    {/* Field 5 */}
                    <div
                      style={{
                        flex: 1,
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          {" "}
                          Pricing Category:
                          <span style={{ color: "red" }}>*</span>{" "}
                        </span>
                        <br />
                        <FormControl
                          style={{
                            width: "220px",
                            marginTop: "2%",
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <Select
                            name="pricing_category_name"
                            value={pricing_category_name}
                            onChange={handleChange2}
                            style={{ height: "45px",color:
                            pricing_category_name? (pricing_category_name === "Currency" ? "#818E94" : currentTheme === "dark" ? "#FFFFFF" : "#000000") : "#818E94", }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  minWidth: "220px",
                                  color:
                                    currentTheme === "dark"
                                      ? "#818E94"
                                      : "black",
                                  background:
                                    currentTheme === "dark"
                                      ? "#292E33"
                                      : "#FFFFFF",
                                },
                              },
                            }}
                          >
                            {category.map((category) => (
                              <MenuItem
                                key={category.pricing_category_id}
                                value={`${category.pricing_category_name}`}
                                style={{
                                  color: "#818E94",
                                  fontSize: "14px",
                                  height: "30px",
                                }}
                              >
                                {`${category.pricing_category_name} `}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Typography>
                    </div>
                    {/* Field 6 */}
                    <div style={{ flex: 1, marginLeft: "10px" }}></div>
                  </div>
                  <br />
                  {/* third Row */}
                  {/* <Typography>categoryType === "type-2"</Typography> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      // marginTop: "-3%"
                    }}
                  >
                    {/* Field 4 */}
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          Free Usage:<span style={{ color: "red" }}>*</span>{" "}
                        </span>
                        <br />
                        <TextField
                          id="free_usage_per_month"
                          value={free_usage_per_month}
                          onChange={(e) => onChange(e)}
                          InputProps={{
                            placeholder: "Hours",
                            inputProps: {
                              style: {
                                color:
                                  currentTheme === "dark"
                                    ? "#FFFFFF"
                                    : "#000000",
                                textTransform: "capitalize",
                                height: "10px",
                                width: "190px"
                              },
                            },
                          }}
                          // label="Password"
                          variant="outlined"
                          required
                          style={{
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          margin="dense"
                        ></TextField>
                      </Typography>
                    </div>
                    {pricing_category_name === "Tiered Pricing" && (
                      <div style={{ display: "flex", flexDirection: 'row', }}>
                        <div style={{ flex: 1, marginRight: "10px", marginLeft: "-560px" }}>
                          <Typography style={{ color: "#818E94" }}>
                            <span
                              style={{
                                color:
                                  currentTheme === "dark" ? "#FFFFFF" : "#000000",
                                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                              }}
                            >
                              Tier 1 Threshold:<span style={{ color: "red" }}>*</span>{" "}
                            </span>
                            <br />
                            <TextField
                              id="min_guarantee_unit"
                              value={min_guarantee_unit}
                              onChange={(e) => onChange(e)}
                              InputProps={{
                                placeholder: "Hours",
                                type: "number",
                                inputProps: {
                                  style: {
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                    textTransform: "capitalize",
                                    height: "10px",
                                    width: "190px"
                                  },
                                },
                              }}
                              // label="Password"
                              variant="outlined"
                              required
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                                borderRadius: "5px",
                              }}
                              margin="dense"
                            ></TextField>
                          </Typography>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            marginRight: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <Typography style={{ color: "#818E94" }}>
                            <span
                              style={{
                                color:
                                  currentTheme === "dark" ? "#FFFFFF" : "#000000",
                                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                              }}
                            >
                              {" "}
                              Unit Price:<span style={{ color: "red" }}>
                                *
                              </span>{" "}
                            </span>
                            <br />
                            <TextField
                              id="unit_price"
                              value={unit_price}
                              onChange={(e) => onChange(e)}
                              type="number"
                              InputProps={{
                                placeholder: "Cost",
                                inputProps: {
                                  style: {
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                    textTransform: "capitalize",
                                    height: "10px",
                                    width: "190px"
                                  },
                                },
                              }}
                              // label="Password"
                              variant="outlined"
                              required
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                                borderRadius: "5px",
                              }}
                              margin="dense"
                            ></TextField>
                          </Typography>

                        </div>
                      </div>
                    )}
                    {/* Field 2 */}
                    {pricing_category_name === "Flat Pricing" && (
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "-240px",
                        }}
                      >
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Unit Price:<span style={{ color: "red" }}>
                              *
                            </span>{" "}
                          </span>
                          <br />
                          <TextField
                            id="unit_price"
                            value={unit_price}
                            onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Cost",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width: "190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>

                      </div>
                    )}

                  </div>
                  <br />
                  {/* forth Row */}
                  {pricing_category_name === "Tiered Pricing" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // marginTop: "-3%"
                      }}
                    >
                      {/* Field 4 */}
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <div
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Unit Price:<span style={{ color: "red" }}>*</span>{" "}<span
                              style={{
                                color: "#fcba03",
                                fontSize: "12px",
                                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                              }}
                            >
                              Applicable Beyond (Tier 1): <span>{" "}{min_guarantee_unit} </span>
                            </span>
                          </div>
                          {/* <br /> */}
                          <TextField
                            id="unit_price_post_guarantee_unit"
                            value={unit_price_post_guarantee_unit}
                            onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Cost",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width: "190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>

                      </div>
                      {/* Field 2 */}

                      {/* Field 3 */}
                      <div style={{ flex: 1, marginLeft: "10px" }}></div>
                    </div>
                  )}
                </form>
                <div style={{ flex: 1, marginTop: categoryType && pricing_category_name === "Tiered Pricing" ? "-2%" : "1%" }}>
                  <DialogActions
                    style={{
                      display: "flex",
                      // marginTop: "3%",
                      // margin: "0vw 5vw",
                      justifyContent: "flex-end",
                    }}
                  >
                    <List
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ListItem
                        style={{
                          color: "#4779EF",
                          // backgroundColor: "#4779EF",
                          borderRadius: "6px",
                          border: "2px solid #4779EF",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          // marginBottom: "10px",
                          // marginLeft: "-20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => {
                          // handleFormAddSubmit();
                          handleClose();
                        }}
                        variant="outlined"
                        required
                        color="secondary"
                      >
                        Cancel
                      </ListItem>
                      <ListItem
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#4779EF",
                          borderRadius: "10px",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          marginRight: "10px",
                          marginLeft: "20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => {
                          handleUpdate()
                        }}
                      >
                        Update
                      </ListItem>
                    </List>
                  </DialogActions>
                </div>
              </DialogContent>
            </Dialog>
            {/* delete dailog */}

          </div>
        </div>
      </div>
    </>
  );
}

