import React, { useContext, useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
//import TextField from "@mui/material/TextField";
import { TextField, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Divider } from "@mui/material";
import "./SuperAdmin.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { ThemePreferenceContext } from "../../../../index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CheckBox } from "@mui/icons-material";
import axios from "../../../../axios/index";
import AuthContext from "../../../../hooks/useAuth";

export default function FormEditDialog({
  open,
  handleClose,
  data,
  onChange,
  onEmail,
  handleFormSubmit,
  onRoleChange,
  onChangePhone,
  onDateChange,
  formData,
  updateId,
}) {
  const {
    org_id,
    org_name,
    admin_email_id,
    pc_first_name,
    pc_last_name,
    contact_address_line_1,
    contact_address_line_2,
    contact_city,
    contact_state_province,
    contact_zip_code,
    contact_country,
    phone_country_code,
    phone_number,
    created_at,
    subscription_type,
    status,
    bc_first_name,
    bc_last_name,
    billing_address_line_1,
    billing_address_line_2,
    billing_city,
    billing_state_province,
    billing_zip_code,
    billing_country,
    tax_id,
  } = data;

  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const [isChecked, setIsChecked] = useState(false);
  const [date, setDate] = useState(new Date());

  // const inputStyles = {
  //   width: '200px',
  //   height: '40px',
  // };

  // const calendarStyles = {
  //   width: '300px', }
  const [currentPage, setCurrentPage] = useState(1); // State to manage current page
  const [countries, SetCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryBill, setSelectedCountryBill] = useState("");

  const auth = useContext(AuthContext);
  useEffect(() => {
    axios
      .get("/countrydetails", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // Sort countries alphabetically
        const sortedCountries = res.data.data.sort((a, b) => a.country_name.localeCompare(b.country_name));
        SetCountries(sortedCountries);
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
  }, []);
  const handleChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  const handleChange2 = (event) => {
    setSelectedCountryBill(event.target.value);
  };

  // Handle next button click
  const handleNext = () => {
    if (currentPage === 1) {
      setCurrentPage(2);
    }
  };
  const handleClose1 = () => {
    setCurrentPage(1);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox state

    if (!isChecked) {
      // If the checkbox was previously unchecked, copy data from the first page to the second page
      // onChange({
      //   target: {
      //     id: "bc_first_name",
      //     value: data.pc_first_name,
      //   },
      // });
      // onChange({
      //   target: {
      //     id: "bc_last_name",
      //     value: data.pc_last_name,
      //   },
      // });
      onChange({
        target: {
          id: "billing_address_line_1",
          value: data.contact_address_line_1,
        },
      });
      onChange({
        target: {
          id: "billing_address_line_2",
          value: data.contact_address_line_2,
        },
      });
      onChange({
        target: {
          id: "billing_city",
          value: data.contact_city,
        },
      });
      onChange({
        target: {
          id: "billing_state_province",
          value: data.contact_state_province,
        },
      });
      onChange({
        target: {
          id: "billing_zip_code",
          value: data.contact_zip_code,
        },
      });
      onChange({
        target: {
          id: "billing_country",
          value: data.contact_country,
        },
      });
      // Repeat this for other fields you want to auto-fill
    } else {
      // If the checkbox was previously checked, clear the fields
      // onChange({
      //   target: {
      //     id: "bc_first_name",
      //     value: "",
      //   },
      // });
      // onChange({
      //   target: {
      //     id: "bc_last_name",
      //     value: "",
      //   },
      // });
      onChange({
        target: {
          id: "billing_address_line_1",
          value: "", // Set it to an empty string to clear the field
        },
      });
      onChange({
        target: {
          id: "billing_address_line_2",
          value: "",
        },
      });
      onChange({
        target: {
          id: "billing_city",
          value: "",
        },
      });
      onChange({
        target: {
          id: "billing_state_province",
          value: "",
        },
      });
      onChange({
        target: {
          id: "billing_zip_code",
          value: "",
        },
      });
      onChange({
        target: {
          id: "billing_country",
          value: "",
        },
      });
      // Repeat this for other fields you want to clear
    }
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            boxShadow: "none",
            minWidth: "800px",
            // width: "600px",
            // width: "40vw",
            maxHeight: "700px",
            backgroundColor: currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
          },
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <DialogTitle
            style={{
              fontSize: "20px",
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            }}
            // id="alert-dialog-title"
          >
            Edit Organization
          </DialogTitle>
          <CancelIcon
            onClick={() => {
              handleClose();
              setCurrentPage(1);
              setIsChecked(false);
                          // added for demo
                          setSelectedCountry();
            }}
            style={{
              marginTop: "2%",
              cursor: "pointer",
              marginRight: "3vh",
              color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
            }}
          />
        </div>
        <Divider
          style={{
            margin: "0vw 1.5vw",
            maxWidth: "100%",
            backgroundColor: "grey",
          }}
        />
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <form>
            {/* First Page */}
            {currentPage === 1 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "20px", // Add margin to separate from the next row
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                  > */}
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <Typography style={{ color: "#818E94" }}>
                      Organization Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                     id="org_name"
                     value={org_name}
                     onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Name",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                    />
                  </div>
                  <div
                    style={{ flex: 1, marginRight: "10px", marginLeft: "10px" }}
                  >
                    <Typography style={{ color: "#818E94" }}>
                      First Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="pc_first_name"
                      value={pc_first_name}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Name",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    <Typography style={{ color: "#818E94" }}>
                      Last Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="pc_last_name"
                      value={pc_last_name}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Name",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Email"
                      variant="outlined"
                      // required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                    />
                  </div>
                </div>
                {/* <br/> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "20px", // Add margin to separate from the next row
                    marginTop: "-2%",
                  }}
                >
                <div style={{ flex: 1, marginRight: "10px" }}>
                  <Typography style={{ color: "#818E94" }}>
                    Primary Email<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextField
                    id="admin_email_id"
                    type="email"
                    value={admin_email_id}
                    onChange={onEmail}
                    InputProps={{
                      placeholder: "Email",
                      inputProps: {
                        style: {
                          color:
                          currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                          width:"180px"
                        },
                      },
                    }}
                    // label="Email"
                    variant="outlined"
                    required
                    style={{
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                      borderRadius: "5px",
                    }}
                    margin="dense"
                  />
                </div>
                <div
                    style={{
                      flex: 1,
                      marginRight: "10px",
                      marginLeft: "-240px",
                    }}
                  >
                    <Typography style={{ color: "#818E94" }}>
                      Tax ID
                    </Typography>
                    <TextField
                      id="tax_id"
                      value={formData?.tax_id}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "ID",
                        // color: validationErrors.tax_id ? "red" : "",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                      // error={validationErrors.tax_id}
                    />
                  </div>
                  </div>
                {/* <br/> */}
                {/* Second Row */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{ flex: 1, marginRight: "10px", marginTop: "1%" }}
                  >
                    <Typography style={{ color: "#818E94" }}>
                      Contact Address<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="contact_address_line_1"
                      value={contact_address_line_1}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Address Line 1",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Password"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                    />
                  </div>
                  <div
                    style={{ flex: 1, marginRight: "10px", marginLeft: "10px" }}
                  >
                    {/* <Typography style={{ color: "#818E94" }}>
                        Address Line 2
                      </Typography> */}
                    <TextField
                      id="contact_address_line_2"
                      value={contact_address_line_2}
                      onChange={onChange}
                      variant="outlined"
                      // required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "Address Line 2 (optional)",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                            width:"180px"
                        },
                      }}
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "16%",
                      }}
                      margin="dense"
                    />
                  </div>
                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    {/* <Typography style={{ color: "#818E94" }}>
                        Contact City<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <TextField
                      id="contact_city"
                      value={contact_city}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "City",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Password"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "16%",
                      }}
                      margin="dense"
                    />
                  </div>
                </div>
                {/* third row */}
                {/* <br/> */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    {/* <Typography style={{ color: "#818E94" }}>
      Contact State<span style={{ color: "red" }}>*</span>
    </Typography> */}
                    <TextField
                      id="contact_state_province"
                      value={contact_state_province}
                      onChange={onChange}
                      variant="outlined"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "State",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                            width:"180px"
                        },
                      }}
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "2%",
                      }}
                      margin="dense"
                    />
                  </div>

                  <div
                    style={{ flex: 1, marginRight: "10px", marginLeft: "10px" }}
                  >
                    {/* <Typography style={{ color: "#818E94" }}>
                        Contact Country<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <Select
                      id="contact_country"
                      value={contact_country}
                      onChange={(e) => onChange(e)}
                      displayEmpty
                      variant="outlined"
                      required
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200, 
                            width: 200, 
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                          },
                        },
                      }}
                      style={{
                        color: selectedCountry === "Country" ? "#818E94" : (currentTheme === "dark" ? "#FFFFFF" : "#000000"),
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        height: "40px",
                        width: "210px",
                        marginTop: "2%",
                      }}
                      margin="dense"
                    >
                      <MenuItem value="" style={{ color: selectedCountry === "Country" ? "#818E94" : (currentTheme === "dark" ? "#818E94" : "#818E94") }}>
                        {selectedCountry ? selectedCountry : "Country"}
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem
                          key={country.country_id}
                          value={country.country_name}
                          style={{
                            color: "#818E94",
                            fontSize: "14px",
                            height: "30px",
                          }}
                        >
                          {country.country_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    {/* <Typography style={{ color: "#818E94" }}>
                        Contact Pin Code<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <TextField
                      id="contact_zip_code"
                      value={contact_zip_code}
                      onChange={onChange}
                      variant="outlined"
                      type="number"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "Pin/Zip Code",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                            width:"180px"
                        },
                      }}
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "2%",
                      }}
                      margin="dense"
                    />
                  </div>
                </div>
                {/* <br/>  */}
                {/* <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ color: "#818E94" }}>
                    Contact No
                  </Typography>
                  <TextField
                     id="phone_number"
                     type="tel"
                     value={phone_number}
                    onChange={(e) => onChange(e)}
                    InputProps={{
                      placeholder: " Phone",
                      style: {
                        color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                        textTransform: "capitalize",
                      },
                    }}
                    // label="Password"
                    variant="outlined"
                        required
                    style={{
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                      borderRadius: "5px",
                    }}
                    margin="dense"
                  />
                </div> */}
                <Typography
                  style={{
                    color: "#818E94",
                    whiteSpace: "nowrap",
                    marginTop: "1%",
                  }}
                >
                  Contact No<span style={{ color: "red" }}>*</span>
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "2px",
                  }}
                >
                  <PhoneInput
                    id="phone_country_code"
                    country={"in"}
                    value={`${phone_country_code}${phone_number}`}
                    inputProps={{
                      required: true,
                      autoFocus: true,
                    }}
                    inputStyle={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                      borderRadius: "5px",
                      width: "210px",
                      height: "39px",
                      // marginTop: "-1px",
                      border: currentTheme === "dark" ? "none" : "",
                    }}
                    onChange={onChangePhone}
                    // disableSearchIcon={false}
                    // enableSearch={true}
                    searchPlaceholder="Search"
                    searchStyle={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                      width: "150px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      padding: "5px",
                      marginBottom: "10px",
                    }}
                    dropdownStyle={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                      // border: "1px solid #ccc",
                      width: "220px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflowX: "hidden",
                    }}
                    buttonStyle={{
                      backgroundColor:
                        currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                      border: currentTheme === "dark" ? "none" : "",
                    }}
                  />
                  {/* <span style={{ marginTop: "-9px", marginLeft: "1px" }}>
                        <TextField
                          id="phone_number"
                          
                          value={formData?.phone_number}
                          onChange={(e) => onChange(e)}
                          InputProps={{
                            placeholder: " Phone",
                            style: {
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              textTransform: "capitalize",
                            },
                          }}
                          // label="Password"
                          variant="outlined"
                        required
                          style={{
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                            width: "160px",
                          }}
                          margin="dense"
                        />
                      </span> */}
                </div>
                {/* </div> */}
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}
                >
                  {/* <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ color: "#818E94" }}>
                        Plan<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <div style={{ color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              textTransform: "capitalize", }}>  <FormControl
                              style={{
                                width: "220px",
                                marginTop: "3%",
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                                  borderRadius: "5px",
                              }}
                            >
                              <Select
                                // name="role"
                                // value={role}
                                // onChange={onRoleChange}
                                style={{ height: "40px", maxWidth: "220px",color:"#818E94", }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxWidth: "220px",
                                      color: currentTheme === "dark" ? "#818E94" : "black",
                                      background: currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="Basic">Basic</MenuItem>
                                <MenuItem value="Premium">Premium</MenuItem>
                                <MenuItem value="Custom">Custom</MenuItem>
                              </Select>
                            </FormControl></div>
                    </div> */}
                </div>

                <div style={{ flex: 1 }}>
                  <DialogActions
                    style={{
                      display: "flex",
                      // marginTop: "3%",
                      // margin: "0vw 5vw",
                      justifyContent: "flex-end",
                    }}
                  >
                    <List
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ListItem
                        style={{
                          color: "#4779EF",
                          // backgroundColor: "#4779EF",
                          borderRadius: "6px",
                          border: "2px solid #4779EF",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          // marginBottom: "10px",
                          // marginLeft: "-20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => handleClose()}
                        variant="outlined"
                        required
                        color="secondary"
                      >
                        Cancel
                      </ListItem>
                      <ListItem
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#4779EF",
                          borderRadius: "6px",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          marginRight: "20px",
                          marginLeft: "20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => {
                          handleNext();
                        }}
                      >
                        Next
                      </ListItem>
                    </List>
                  </DialogActions>
                </div>
              </>
            )}
          </form>
          <form>
            {/* Second Page */}
            {currentPage === 2 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                    // marginTop:"-7%"
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      flexDirection: "column",
                    }}
                  > */}
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <Typography style={{ color: "#818E94" }}>
                      First Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="bc_first_name"
                      value={
                        bc_first_name}
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Name",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                    />
                  </div>
                  <div
                    style={{
                      flex: 1,
                      marginRight: "10px",
                      marginLeft: "-230px",
                    }}
                  >
                    <Typography style={{ color: "#818E94" }}>
                    Last Name<span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="bc_last_name"
                      value={
                        bc_last_name
                      }
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Name",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Name"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                    />
                  </div>
                </div>
                {/* <div
  style={{
    display: "flex",
    alignItems: "center",
  }}
>
  <input
    type="checkbox"
    // checked={isCheckedName}
    // onClick={handleNameCheckBox}
    color="primary"
    style={{ width: "15px", height: "15px", cursor: "pointer" }}
  />
  <span style={{ marginLeft: "1%", color: "#818E94" }}>
    Same as Contact Name
  </span>
</div> */}
                <br/>
                {/* <br/> */}
                {/* Second Row */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    <Typography style={{ color: "#818E94" }}>
                      Billing Address
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      id="billing_address_line_1"
                      value={
                        billing_address_line_1
                      }
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "Address Line 1",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Password"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                    />
                  </div>
                  <div
                    style={{ flex: 1, marginRight: "10px", marginLeft: "10px" }}
                  >
                    {/* <Typography style={{ color: "#818E94" }}>
                        Billing Address Line 2
                      </Typography> */}
                    <TextField
                      id="billing_address_line_2"
                      value={
                        billing_address_line_2
                      }
                      onChange={onChange}
                      variant="outlined"
                      // required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "Address Line 2 (optional)",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                            width:"180px"
                        },
                      }}
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "13%",
                      }}
                      margin="dense"
                    />
                  </div>

                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    {/* <Typography style={{ color: "#818E94" }}>
                        Billing City<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <TextField
                      id="billing_city"
                      value={
                        billing_city
                      }
                      onChange={(e) => onChange(e)}
                      InputProps={{
                        placeholder: "City",
                        inputProps: {
                          style: {
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            textTransform: "capitalize",
                            height:"20px",
                            width:"180px"
                          },
                        },
                      }}
                      // label="Password"
                      variant="outlined"
                      required
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        marginTop: "13%",
                      }}
                      margin="dense"
                    />
                  </div>
                </div>
                <br />
                {/* Third Row */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ flex: 1, marginRight: "10px" }}>
                    {/* <Typography style={{ color: "#818E94" }}>
                        Billing State<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <TextField
                      id="billing_state_province"
                      value={
                        billing_state_province
                      }
                      onChange={onChange}
                      variant="outlined"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "State",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                            width:"180px"
                        },
                      }}
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                    />
                  </div>
                  <div
                    style={{ flex: 1, marginRight: "10px", marginLeft: "10px" }}
                  >
                    {/* <Typography style={{ color: "#818E94" }}>
                        Billing Country<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <Select
                      id="billing_country"
                      value={billing_country}
                      // value={ data.contact_country : formData?.billing_country}
                      onChange={handleChange2}
                      displayEmpty
                      variant="outlined"
                      required
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200, // adjust as needed
                            width: 200, // adjust as needed
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                          },
                        },
                      }}
                      style={{
                        color: selectedCountry === "Country" ? "#818E94" : (currentTheme === "dark" ? "#FFFFFF" : "#000000"),
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                        height: "40px",
                        width: "210px",
                        marginTop: "2%",
                      }}
                      margin="dense"
                    >
                      <MenuItem value="" style={{ color: selectedCountry === "Country" ? "#818E94" : (currentTheme === "dark" ? "#818E94" : "#818E94") }}>
                        {selectedCountryBill ? selectedCountryBill : "Country"}
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem
                          key={country.country_id}
                          value={country.country_name}
                          style={{
                            color: "#818E94",
                            fontSize: "14px",
                            height: "30px",
                          }}
                        >
                          {country.country_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div style={{ flex: 1, marginLeft: "10px" }}>
                    {/* <Typography style={{ color: "#818E94" }}>
                        Billing Pin Code<span style={{ color: "red" }}>*</span>
                      </Typography> */}
                    <TextField
                      id="billing_zip_code"
                      value={
                        billing_zip_code
                      }
                      onChange={onChange}
                      variant="outlined"
                      type="number"
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        placeholder: "Pin/Zip Code",
                        style: {
                          color:
                            currentTheme === "dark" ? "#FFFFFF" : "#000000",
                          textTransform: "capitalize",
                          height:"20px",
                            width:"180px"
                        },
                      }}
                      style={{
                        backgroundColor:
                          currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                        borderRadius: "5px",
                      }}
                      margin="dense"
                    />
                  </div>
                </div>

                <br />
                {/* <br />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    // checked={isCheckedName}
                    // onClick={handleNameCheckBox}
                    color="primary"
                    style={{ width: "15px", height: "15px", cursor: "pointer" }}
                  />
                  <span style={{ marginLeft: "1%", color: "#818E94" }}>
                    Same as Contact Name
                  </span>
                </div> */}
                {/* <br /> */}
                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onClick={handleCheckboxChange}
                    color="primary"
                    style={{ width: "15px", height: "15px", cursor: "pointer" }}
                  />
                  <span style={{ marginLeft: "1%", color: "#818E94" }}>
                    Same as Contact Address
                  </span>
                </div> */}

                <div style={{ flex: 1, marginTop: "3%" }}>
                  <DialogActions
                    style={{
                      display: "flex",
                      // marginTop: "3%",
                      // margin: "0vw 5vw",
                      justifyContent: "flex-end",
                    }}
                  >
                    <List
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ListItem
                        style={{
                          color: "#4779EF",
                          // backgroundColor: "#4779EF",
                          borderRadius: "6px",
                          border: "2px solid #4779EF",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          // marginBottom: "10px",
                          // marginLeft: "-20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => setCurrentPage(1)}
                        variant="outlined"
                        required
                        color="secondary"
                      >
                        Back
                      </ListItem>
                      <ListItem
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: "#4779EF",
                          borderRadius: "10px",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          marginRight: "20px",
                          marginLeft: "20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => {
                          handleFormSubmit();
                          handleClose();
                          setCurrentPage(1);
                          setIsChecked(false);
                          // added for demo
                          setSelectedCountry();
                        }}
                      >
                        Update
                      </ListItem>
                    </List>
                  </DialogActions>
                </div>
              </>
            )}
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}