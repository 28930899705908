import React, { useContext, useState, useEffect } from "react";
import { ThemePreferenceContext, themesMap } from "../../../../index";
import "./Settings.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Divider, TextField, InputAdornment } from "@mui/material";
import AuthContext from "../../../../hooks/useAuth";
import axios from "../../../../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dateFormat, { masks } from "dateformat";

export default function CreatePricingBook({
  open,
  handleClose,
  data,
  onChange,
  onEmail,
  handleFormSubmit,
  onChangeCurrency,
  onChangeUnitType,
  onChangeCategoryType,
  onDateChange,
  pricingData,
  updateId,
  updatePbId,
  onChange3,
  onChange2,
  onEffectiveDateChange,
}) {
  const {
    org_id,
    pb_id,
    pb_name,
    effective_from,
    effective_to,
    currency,
    created_at,
    created_by,
    pricing_category_id,
    pricing_category_name,
    free_usage_per_month,
    unit_type,
    unit_price,
    min_guarantee_unit,
    unit_price_post_guarantee_unit,
    due_date_threshold,
  } = data;
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const auth = useContext(AuthContext);
  const role = auth.role;
  const currentTimestamp = new Date().toISOString();
  const [currencyFrom, setCurrencyFrom] = useState("");
  const [unitType, setUnitType] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const handleChange = (event) => {
    // console.log("u-type before");
    setUnitType(event.target.value);
    // console.log("u-type after", event.target.value);
    onChangeUnitType(event.target.value);
  };
  const handleChange2 = (event) => {
    // console.log("c-type before");
    setCategoryType(event.target.value);
    // console.log("c-type after", event.target.value);
    onChangeCategoryType(event.target.value);
  };
  const handleCurrency = (event) => {
    // const { value } = event.target;
    setCurrencyFrom(event.target.value);
    onChangeCurrency(event.target.value);
  };

  const [currencies, SetCurrencies] = useState([]);
  useEffect(() => {
    axios
      .get("/currencydetails", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const sortedCurrencies = res.data.data.sort((a, b) =>
          a.currency_description.localeCompare(b.currency_description)
        );
        SetCurrencies(sortedCurrencies);
      });
  }, []);

  const [category, setCategory] = useState([]);

  useEffect(() => {
    axios
      .get("/pricingcategory", {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCategory(res.data.data);
      });
  }, []);
  const now = new Date();

  const handleCreate = () => {
    // if (validateForm()) {
    handleClose();
    handleFormSubmit();
    setCategoryType();
    setUnitType("");
    setCurrencyFrom("");
    // }
    // else {
    //   toast.error("Please fill in all required fields!", {
    //     position: toast.POSITION.BOTTOM_CENTER,
    //   });
    // }
  }
  const isFormValid = () => {
    return (
      pb_name &&
      currency &&
      unitType &&
      effective_from &&
      categoryType &&
      free_usage_per_month &&
      unit_price &&
      unit_price_post_guarantee_unit
    );
  };

  return (
    <>
      <div style={{ marginTop: "4rem" }}>
        <div style={{ marginTop: "2%" }}>
          <div
            style={{
              display: "flex",
              overflowX: "auto",
              width: "70vw",
              // padding: "20px 0",
              marginLeft: "5%",
              // background:"red",
              justifyContent: "center",
              marginTop: "1.5%",
            }}
          >
            {/* adding dailog */}
            <Dialog
              PaperProps={{
                style: {
                  boxShadow: "none",
                  // maxWidth: "xl",
                  minWidth: categoryType === "Tiered Pricing" ? "900px" : "800px",
                  // width: "40vw",
                  minHeight: categoryType === "Tiered Pricing" ? "570px" : "500px",
                  backgroundColor:
                    currentTheme === "dark" ? "#1F2327" : "#FFFFFF",
                  overflow: "hidden"
                },
              }}
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <DialogTitle
                  style={{
                    fontSize: "20px",
                    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                    color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                  }}
                // id="alert-dialog-title"
                >
                  Add Pricing Book
                </DialogTitle>
                <CancelIcon
                  onClick={() => {
                    handleClose();
                    setCategoryType();
                    setUnitType();
                    setCurrencyFrom();
                  }}
                  style={{
                    marginTop: "2%",
                    cursor: "pointer",
                    marginRight: "3vh",
                    color: currentTheme === "dark" ? "#FFFFFF" : "#000000",
                  }}
                />
              </div>
              <Divider
                style={{
                  margin: "0vw 1.5vw",
                  width: "93%",
                  backgroundColor: "grey",
                }}
              />
              <DialogContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  // marginTop:"-5%"
                }}
              >
                <form style={{ overflow: "hidden" }}>
                  {/* First Row */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    {/* Field 1 */}
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          Pricing Book Name:
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <br />
                        <TextField
                          id="pb_name"
                          value={pricingData?.pb_name}
                          onChange={(e) => onChange(e)}
                          InputProps={{
                            placeholder: "Name",
                            inputProps: {
                              style: {
                                color:
                                  currentTheme === "dark"
                                    ? "#FFFFFF"
                                    : "#000000",
                                textTransform: "capitalize",
                                height: "10px",
                                width: "190px"
                              },
                            },
                          }}
                          // label="Password"
                          variant="outlined"
                          required
                          style={{
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          margin="dense"
                        ></TextField>
                      </Typography>
                    </div>
                    {/* Field 2 */}

                    <div
                      style={{
                        flex: 1,
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          {" "}
                          Currency:
                          <span style={{ color: "red" }}>*</span>{" "}
                        </span>
                        <br />
                        <FormControl
                          style={{
                            width: "220px",
                            marginTop: "2%",
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <Select style={{ height: "45px",  color:
                        currencyFrom ? (currencyFrom === "Currency" ? "#818E94" : currentTheme === "dark" ? "#FFFFFF" : "#000000") : "#818E94",}}
                            id="currency"
                            onChange={handleCurrency}
                            value={currencyFrom}
                            displayEmpty
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxWidth: "220px",
                                  color:
                                    currentTheme === "dark"
                                      ? "#818E94"
                                      : "black",
                                  background:
                                    currentTheme === "dark"
                                      ? "#292E33"
                                      : "#FFFFFF",
                                  height: "200px",
                                },
                                className:"ScrollBars"
                              },
                            }}>
                              {/* <MenuItem value="" style={{ color: "#818E94",fontSize: "14px", }}>
                        {currencyFrom ? currencyFrom : "Currency"}
                      </MenuItem> */}
                            {currencies.map((currency) => (
                              <MenuItem
                                key={currency.currency_id}
                                value={`${currency.currency_symbol.replace(/:.*/, '')}`}
                                style={{
                                  color: "#818E94",
                                  fontSize: "14px",
                                  height: "30px",
                                }}
                              >
                                {`${currency.currency_description} (${currency.currency_symbol.replace(/:.*/, '')})`}
                              </MenuItem>
                            ))}


                          </Select>
                        </FormControl>
                      </Typography>
                    </div>
                    {/* Field 3 */}
                    <div style={{ flex: 1, marginLeft: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          {" "}
                          Unit Type:<span style={{ color: "red" }}>
                            *
                          </span>{" "}
                        </span>
                        <br />
                        <FormControl
                          style={{
                            width: "220px",
                            marginTop: "2%",
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <Select
                            id="unit_type"
                            value={unitType}
                            onChange={handleChange}
                            style={{ height: "45px",  color:
                            unitType ? (unitType === "Type" ? "#818E94" : currentTheme === "dark" ? "#FFFFFF" : "#000000") : "#818E94", }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  minWidth: "220px",
                                  color:
                                    currentTheme === "dark"
                                      ? "#818E94"
                                      : "black",
                                  background:
                                    currentTheme === "dark"
                                      ? "#292E33"
                                      : "#FFFFFF",
                                },
                              },
                            }}
                          >
                            <MenuItem value="Hour"style={{
                                  color: "#818E94",
                                  // fontSize: "16px",
                                  height: "30px",
                                }}>Hours</MenuItem>
                            <MenuItem value="Minute"style={{
                                  color: "#818E94",
                                  // fontSize: "14px",
                                  height: "30px",
                                }}>Minutes</MenuItem>
                          </Select>
                        </FormControl>
                      </Typography>
                    </div>
                  </div>
                  <br />
                  {/* second row */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "-3%",
                    }}
                  >
                    {/* Field 4 */}
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          {" "}
                          Effective From:
                          <span style={{ color: "red" }}>*</span>{" "}
                        </span>
                        <br />
                        <TextField
                          // style={{
                          //   borderRadius: "5px",
                          //   width: "22.5vw",
                          //   marginTop: "2%",
                          //   backgroundColor:
                          //     currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                          // }}
                          id="effective_from"
                          type="date"
                          // label="End Date"
                          value={effective_from}
                          onChange={onEffectiveDateChange}
                          variant="outlined"
                          style={{
                            width: "220px",
                            marginTop: "2%",
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            style: {
                              padding: 8.5,
                              color:
                                currentTheme === "dark" ? "#818E94" : "black",
                              height: "28px"
                            },
                          }}
                          margin="dense"
                        />
                      </Typography>
                    </div>
                    {/* Field 5 */}
                    <div
                      style={{
                        flex: 1,
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          {" "}
                          Pricing Category:
                          <span style={{ color: "red" }}>*</span>{" "}
                        </span>
                        <br />
                        <FormControl
                          style={{
                            width: "220px",
                            marginTop: "2%",
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <Select
                            name="category_type"
                            value={categoryType}
                            onChange={handleChange2}
                            style={{ height: "45px",  color:
                            categoryType ? (categoryType === "Country" ? "#818E94" : currentTheme === "dark" ? "#FFFFFF" : "#000000") : "#818E94", }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  minWidth: "220px",
                                  color:
                                    currentTheme === "dark"
                                      ? "#818E94"
                                      : "black",
                                  background:
                                    currentTheme === "dark"
                                      ? "#292E33"
                                      : "#FFFFFF",
                                },
                              },
                            }}
                          >
                            {category.map((category) => (
                              <MenuItem
                                key={category.pricing_category_id}
                                value={`${category.pricing_category_name}`}
                                style={{
                                  color: "#818E94",
                                  fontSize: "14px",
                                  height: "30px",
                                }}
                              >
                                {`${category.pricing_category_name} `}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Typography>
                    </div>
                    {/* Field 6 */}
                    <div style={{ flex: 1, marginLeft: "10px" }}></div>
                  </div>
                  <br />
                  {/* third Row */}
                  {/* <Typography>categoryType === "Tiered Pricing"</Typography> */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      // marginTop: "-3%"
                    }}
                  >
                    {/* Field 4 */}
                    <div style={{ flex: 1, marginRight: "10px" }}>
                      <Typography style={{ color: "#818E94" }}>
                        <span
                          style={{
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                          }}
                        >
                          Free Usage:<span style={{ color: "red" }}>*</span>{" "}
                        </span>
                        <br />
                        <TextField
                          id="free_usage_per_month"
                          value={pricingData?.free_usage_per_month}
                          onChange={(e) => onChange(e)}
                          type="number"
                          InputProps={{
                            placeholder: unitType === 'Hour' ? 'Hours' : unitType === 'Minute' ? 'Minutes' : "Hours",
                            inputProps: {
                              style: {
                                color:
                                  currentTheme === "dark"
                                    ? "#FFFFFF"
                                    : "#000000",
                                textTransform: "capitalize",
                                height: "10px",
                                width: "190px"
                              },
                            },
                          }}
                          // label="Password"
                          variant="outlined"
                          required
                          style={{
                            backgroundColor:
                              currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                            borderRadius: "5px",
                          }}
                          margin="dense"
                        ></TextField>
                      </Typography>
                    </div>
                    {categoryType === "Tiered Pricing" && (
                      <div style={{ display: "flex", flexDirection: 'row', }}>
                        <div style={{ flex: 1, marginRight: "10px", marginLeft: "-560px" }}>
                          <Typography style={{ color: "#818E94" }}>
                            <span
                              style={{
                                color:
                                  currentTheme === "dark" ? "#FFFFFF" : "#000000",
                                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                              }}
                            >
                              Tier 1 Threshold:<span style={{ color: "red" }}>*</span>{" "}
                            </span>
                            <br />
                            <TextField
                              id="min_guarantee_unit"
                              value={pricingData?.min_guarantee_unit}
                              onChange={(e) => onChange(e)}
                              InputProps={{
                                placeholder: unitType === 'Hour' ? 'Hours' : unitType === 'Minute' ? 'Minutes' : "Hours",
                                type: "number",
                                inputProps: {
                                  style: {
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                    textTransform: "capitalize",
                                    height: "10px",
                                    width: "190px"
                                  },
                                },
                              }}
                              // label="Password"
                              variant="outlined"
                              required
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                                borderRadius: "5px",
                              }}
                              margin="dense"
                            ></TextField>
                          </Typography>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            marginRight: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <Typography style={{ color: "#818E94" }}>
                            <span
                              style={{
                                color:
                                  currentTheme === "dark" ? "#FFFFFF" : "#000000",
                                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                              }}
                            >
                              {" "}
                              Unit Price:<span style={{ color: "red" }}>
                                *
                              </span>{" "}
                            </span>
                            <br />
                            <TextField
                              id="unit_price"
                              value={pricingData?.unit_price}
                              onChange={(e) => onChange(e)}
                              type="number"
                              InputProps={{
                                placeholder: "Cost",
                                inputProps: {
                                  style: {
                                    color:
                                      currentTheme === "dark"
                                        ? "#FFFFFF"
                                        : "#000000",
                                    textTransform: "capitalize",
                                    height: "10px",
                                    width: "190px"
                                  },
                                },
                              }}
                              // label="Password"
                              variant="outlined"
                              required
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                                borderRadius: "5px",
                              }}
                              margin="dense"
                            ></TextField>
                          </Typography>

                        </div>
                      </div>
                    )}
                    {/* Field 2 */}
                    {categoryType !== "Tiered Pricing" && (
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "-240px",
                        }}
                      >
                        <Typography style={{ color: "#818E94" }}>
                          <span
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            {" "}
                            Unit Price:<span style={{ color: "red" }}>
                              *
                            </span>{" "}
                          </span>
                          <br />
                          <TextField
                            id="unit_price"
                            value={pricingData?.unit_price}
                            onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Cost",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width: "190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>

                      </div>
                    )}
                  </div>
                  <br />
                  {/* forth Row */}
                  {categoryType === "Tiered Pricing" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // marginTop: "-3%"
                      }}
                    >
                      {/* Field 4 */}
                      <div style={{ flex: 1, marginRight: "10px" }}>
                        <Typography style={{ color: "#818E94" }}>
                          <div
                            style={{
                              color:
                                currentTheme === "dark" ? "#FFFFFF" : "#000000",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                            }}
                          >
                            Unit Price:<span style={{ color: "red" }}>*</span>{" "}<span
                              style={{
                                color: "#fcba03",
                                fontSize: "12px",
                                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif`,
                              }}
                            >
                              Applicable Beyond (Tier 1) <span>{" "} </span>
                            </span>
                          </div>
                          {/* <br /> */}
                          <TextField
                            id="unit_price_post_guarantee_unit"
                            value={pricingData?.unit_price_post_guarantee_unit}
                            onChange={(e) => onChange(e)}
                            type="number"
                            InputProps={{
                              placeholder: "Cost",
                              inputProps: {
                                style: {
                                  color:
                                    currentTheme === "dark"
                                      ? "#FFFFFF"
                                      : "#000000",
                                  textTransform: "capitalize",
                                  height: "10px",
                                  width: "190px"
                                },
                              },
                            }}
                            // label="Password"
                            variant="outlined"
                            required
                            style={{
                              backgroundColor:
                                currentTheme === "dark" ? "#292E33" : "#FFFFFF",
                              borderRadius: "5px",
                            }}
                            margin="dense"
                          ></TextField>
                        </Typography>
                      </div>
                      {/* Field 2 */}
                      <div
                        style={{
                          flex: 1,
                          marginRight: "10px",
                          marginLeft: "10px",
                          // marginTop:"3%"
                        }}
                      >

                      </div>
                      {/* Field 3 */}
                      <div style={{ flex: 1, marginLeft: "10px" }}></div>
                    </div>
                  )}
                </form>
                <div style={{ flex: 1, marginTop: categoryType === "Tiered Pricing" ? "-2%" : "1%" }}>
                  <DialogActions
                    style={{
                      display: "flex",
                      // marginTop: "3%",
                      // margin: "0vw 5vw",
                      justifyContent: "flex-end",
                    }}
                  >
                    <List
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ListItem
                        style={{
                          color: "#4779EF",
                          // backgroundColor: "#4779EF",
                          borderRadius: "6px",
                          border: "2px solid #4779EF",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          // marginBottom: "10px",
                          // marginLeft: "-20px",
                          fontWeight: "600",
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => {
                          handleClose();
                          setCategoryType();
                          setUnitType();
                          setCurrencyFrom();
                        }}
                        variant="outlined"
                        required
                        color="secondary"
                      >
                        Cancel
                      </ListItem>
                      <ListItem
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: isFormValid() ? '#4779EF' : '#818E94',
                          borderRadius: "10px",
                          width: "12vw",
                          height: "40px",
                          fontSize: "15px",
                          justifyContent: "center",
                          marginRight: "10px",
                          marginLeft: "20px",
                          fontWeight: "600",
                          cursor: isFormValid() ? 'pointer' : 'not-allowed',
                          fontFamily:
                            "normal normal bold 16px/21px Product Sans",
                        }}
                        button
                        onClick={() => {
                          if (isFormValid()) {
                            handleCreate();
                          }
                        }}
                      >
                        Create
                      </ListItem>
                    </List>
                  </DialogActions>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
}

