import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Sidebar.css";
import indlogo from "../../../../asset/indlogo.png";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ArticleIcon from "@mui/icons-material/Article";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import AuthContext from "../../../../hooks/useAuth";
import List from "@mui/material/List";
import { Link, useLocation, Outlet } from "react-router-dom";
import { ThemePreferenceContext } from "../../../../index";
import { Person4Outlined } from "@mui/icons-material";
import ReceiptIcon from '@mui/icons-material/Receipt';

function Sidebar() {
  const auth = useContext(AuthContext);
  // const navigate = useNavigate();

  const location = useLocation();

  // const handleLogout = () => {
  //   auth.logout();
  //   navigate("/login");
  // };
  const [selectedItem, setSelectedItem] = useState(location.pathname);

  useEffect(() => {
    setSelectedItem(location.pathname);
  }, [location.pathname]);

  const role = auth.role;

  const handleItemClick = (to) => {
    setSelectedItem(to);
  };

  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        height: "100%",
        backgroundColor: currentTheme === "dark" ? "#16191C" : "#FFFFFF",
        width: "15vw",
        zIndex: 1200,
        // boxShadow: "5px 0px 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <List style={{ marginTop: "0%" }}>
        <div style={{ display: "flex", marginLeft: 30, marginTop: 10 }}>
          <img src={indlogo} alt="Logo" className="logo" />
          <h2
            style={{
              color: currentTheme === "dark" ? "#F5F6FA" : "#51459E",
            }}
            className="gamemon"
          >
            GameMon
          </h2>
        </div>
        <div style={{ margin: "20px 12px" }}>
          <div
            className="sidebar"
            style={{
              backgroundColor: currentTheme === "dark" ? "#16191C" : "#FFFFFF",
            }}
          >
            <div className="sidebarWrapper">
              <div className="sidebarMenu">
                <ul className="sidebarList">
                  {role === "admin" || role === "tester" ? (
                    <>
                      <Link to="/Dashboard" className="link">
                        <li
                          className={
                            location.pathname === "/Dashboard"
                              ? "sidebarListItem active"
                              : "sidebarListItem"
                          }
                        >
                          <EqualizerIcon className="sidebarIcon" />
                          <p className="menu-font">Dashboard</p>
                        </li>
                      </Link>
                      <Link to="/Sessions" className="link">
                        <li
                          className={
                            location.pathname === "/Sessions"
                              ? "sidebarListItem active"
                              : "sidebarListItem"
                          }
                        >
                          <PersonOutlineIcon className="sidebarIcon" />
                          <p className="menu-font">Sessions</p>
                        </li>
                      </Link>
                      <Link to="/Projects" className="link">
                        <li
                          className={
                            location.pathname === "/Projects"
                              ? "sidebarListItem active"
                              : "sidebarListItem"
                          }
                        >
                          <ArticleIcon className="sidebarIcon" />
                          <p className="menu-font">Projects</p>
                        </li>
                      </Link>
                      <Link to="/SessionComparison" className="link">
                        <li
                          className={
                            location.pathname === "/SessionComparison"
                              ? "sidebarListItem active"
                              : "sidebarListItem"
                          }
                        >
                          <QuestionAnswerIcon className="sidebarIcon" />
                          <p className="menu-font">Comparison</p>
                        </li>
                      </Link>
                    </>
                  ) : null}

                  {role === "admin" && (
                    <Link to="/Admin" className="link">
                      <li
                        className={
                          location.pathname === "/Admin"
                            ? "sidebarListItem active"
                            : "sidebarListItem"
                        }
                      >
                        <Person4Outlined className="sidebarIcon" />
                        <p className="menu-font">Users</p>
                      </li>
                    </Link>
                  )}

                  {role === "super_admin" && (
                    <>
                      <Link to="/SuperAdmin" className="link">
                        <li
                          className={
                            location.pathname === "/SuperAdmin"
                              ? "sidebarListItem active"
                              : "sidebarListItem"
                          }
                        >
                          <Person4Outlined className="sidebarIcon" />
                          <p className="menu-font">Organizations</p>
                        </li>
                      </Link>
                      <Link to="/Invoice" className="link">
                        <li
                          className={
                            location.pathname === "/Invoice"
                              ? "sidebarListItem active"
                              : "sidebarListItem"
                          }
                        >
                          <ReceiptIcon className="sidebarIcon" />
                          <p className="menu-font">Invoice Listing</p>
                        </li>
                      </Link>
                    </>
                  )}
{role !== "super_admin" && (
                  <Link to="/Settings" className="link">
                    <li
                      className={
                        location.pathname === "/Settings"
                          ? "sidebarListItem active"
                          : "sidebarListItem"
                      }
                    >
                      <SettingsIcon className="sidebarIcon" />
                      <p className="menu-font">Settings</p>
                    </li>
                  </Link>
                    )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </List>
    </div>
  );
}

function MainContent() {
  return (
    <div
      // className="main-content"
      style={{
        marginLeft: "16vw",
        // paddingBottom: "100px",
        padding: 16,
        marginTop: -50,
      }}
    >
      <Outlet />
    </div>
  );
}

function App() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  const isLoggedIn = auth.isLoggedIn;
  const role = auth.role;

  return (
    <div style={{ display: "flex" }}>
      {isLoggedIn && <Sidebar />}
      <MainContent />
    </div>
  );
}

export default App;
