import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  id: "",
  org_id: "",
  role: "",
  name: "",
  email: "",
  organization_name:"",
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  userId: () => {},
  compDataHandler: () => {},
  compData: "",
});

export const AuthContextProvider = (props) => {
  const initialToken = sessionStorage.getItem("token");
  const initialId = sessionStorage.getItem("id");
  const OrgId = sessionStorage.getItem("org_id");
  const initialRole = sessionStorage.getItem("role");
  const userName = sessionStorage.getItem("name");
  const emailId = sessionStorage.getItem("email");
  const OrgName = sessionStorage.getItem("organization_name");
  const [token, setToken] = useState(initialToken);
  const [userInfo, setUserInfo] = useState({});
  const [role, setRole] = useState(initialRole);
  const [id, setId] = useState(initialId);
  const [orgid, setOrgId] = useState(OrgId);
  const [name, setName] = useState(userName);
  const [email, setEmail] = useState(emailId);
  const [orgName, setorgName] = useState(OrgName);
  const [compData, setCompData] = useState(null);

  const userIsLoggedIn = !!token;
  const loginHandler = (data) => {
    setUserInfo(data);
    setToken(data.token);
    setRole(data.role);
    setId(data.id);
    setOrgId(data.org_id);
    setName(data.user_Name);
    setEmail(data.email);
    setorgName(data.organization_name)
    sessionStorage.setItem("token", data.token);
    sessionStorage.setItem("id", data.id);
    sessionStorage.setItem("org_id", data.org_id);
    sessionStorage.setItem("role", data.role);
    sessionStorage.setItem("name", data.user_Name);
    sessionStorage.setItem("email", data.email);
    sessionStorage.setItem("organization_name",data.organization_name)
  };

  const compDataHandler = (data) => {
    setCompData(data);
  };
  const userIdHandler = (id) => {
    setId(id);
  };

  const logoutHandler = () => {
    // Clear all relevant items from sessionStorage
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("org_id");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("organization_name")
    // Clear state
    setToken(null);
    setRole(null);
    setName(null);
    setEmail(null);
    setorgName(null);
    setId(null);
  };

  const usersInfo = (data) => {
    setUserInfo(data);
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    userInfo: userInfo,
    role: role,
    id: id,
    org_id: orgid,
    compData: compData,
    login: loginHandler,
    logout: logoutHandler,
    settinguserInfo: usersInfo,
    userId: userIdHandler,
    name: name,
    email: email,
    organization_name:orgName,
    compDataHandler: compDataHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
