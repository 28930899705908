// IMPORTING APIS
import React , { useContext } from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Outlet } from "react-router-dom";
import indlogo from "../../asset/Group.png";
import { ThemePreferenceContext } from "../../../src/index";
// LOCAL-STYLING
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const SharingHeader = (props) => {
  const classes = useStyles();
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  return (
    <div className={classes.root}>
      <AppBar elevation={0.8} style={{ backgroundColor: "#FFFFFF" }}>
        <Toolbar style={{ minHeight: "70px" }}>
          <img
            src={indlogo}
            alt="IndLogo"
            style={{ width: "3%", height: "1%" }}
          />
          <h2
            style={{
              float: "left",
              marginLeft: "7px",
              fontSize: "21px",
              paddingBottom: "0.5%",
              color: currentTheme === "dark" ? "#F5F6FA" : "#51459E",
            }}
          >
            GameMon
          </h2>
        </Toolbar>
      </AppBar>
      <Outlet />
    </div>
  );
};

export default SharingHeader;
