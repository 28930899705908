import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import axios from "../../../../axios/index";
import AuthContext from "../../../../hooks/useAuth";
import { ThemePreferenceContext } from "../../../../index";
import MetricUsage from "../../Pages/Sessions/MetricUsage";
import MetricAppPower from "../Sessions/MetricAppPower";
import MetricCpuMain from "../Sessions/MetricCpuMain";
import MetricDownloadData from "../Sessions/MetricDownloadData";
import MetricFpsMain from "../Sessions/MetricFpsMain";
import MetricFpsStability from "../Sessions/MetricFpsStability";
import MetricGpuMain from "../Sessions/MetricGpuMain";
import MetricMemory from "../Sessions/MetricMemory";
import MetricPeakMemory from "../Sessions/MetricPeakMemory";
import MetricUploadData from "../Sessions/MetricUploadData";
import "./Sharing.css";

export default function Home(props) {
  const [uid, setUid] = useState(null);
  const auth = useContext(AuthContext);
  const currentUserId = JSON.parse(
    sessionStorage.getItem("currentSessionData")
  );

  useEffect(() => {
    const authID = auth.id || currentUserId;
    if (authID) {
      console.log("authID", authID)
      console.log("UID", authID[0].user_id);
      setUid(authID[0].user_id);
    } else {
      console.error("authID is null or undefined");
    }
  }, [auth]);

  const role = auth.role;
  const [Secondsessiondata, SetSecondsessiondata] = React.useState([]);
  const [allSession, setAllSession] = useState([]);

  const [selectedGraph, setSelectedGraph] = useState(null);
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  ;

  const [device, setDevices] = useState("");
  const [epoch_time, setEpoch_time] = useState("");
  const [sessions_id, setSessions_id] = useState("");
  const [appName, setAppName] = useState([]);
  const [sessionNoteDrop, setSessionNoteDrop] = useState(false);
  const [selectedSessionTitle, setSelectedSessionTitle] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rotationAngle, setRotationAngle] = useState(0);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
    setRotationAngle(0);
  };

  const handleZoomIconClick = () => {
    if (imageLoaded) {
      setIsModalOpen(true);
      openModal(timeStamp);
    } else {
      setTimenull("a");
      toast.error("Kindly,Pick a Image to Explore!", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  let isPortrait = false;

  const rotateImage = () => {
    setRotationAngle(rotationAngle - 90);
  };




  const [notes, setNotes] = useState("");


  //Log file Download function
  async function opener() {
    const response = await fetch(
      `https://gamemon-service.indiumsoft.com/downloadlogs?fileName=${Secondsessiondata.session_id}`
    );
    if (response.status === 200) {
      window.open(
        `https://gamemon-service.indiumsoft.com/downloadlogs?fileName=${Secondsessiondata.session_id}`
      );
    } else {
      toast.error("No Log File for this Session !", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      var timer = setInterval(function () {
        clearInterval(timer);
      }, 1000);
    }
  }


  //Display session notes
  function sessiondressdetails() {
    console.log("sessionid", sessions_id)
    axios
      .get("/fetchallSessionNotes", {
        params: {
          sessionID: sessions_id,
        },
      })
      .then((res) => {
        console.log("SESSION NOTES", res.data.data)
        setNotes(res.data.data);
        sessionStorage.setItem(
          "currentSessionData",
          JSON.stringify(res.data.data)
        );
      });
  }

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  function singleSessionItem2(e, data, id) {
    console.log("existed")
    axios
      .get(`https://gamemon-service.indiumsoft.com/shareSessionDetails?sessionId=${sessions_id}&idn=${epoch_time}`)
      .then((res) => {
        console.log("ans", res.data.data[0])
        SetSecondsessiondata(res.data.data[0]);
        sessiondressdetails();
        console.log("device", res.data.data[0].device_name)
        setDevices(res.data.data[0].device_id)
        setAppName(res.data.data[0].app_name)
        setUid(res.data.data[0].user_id)
        // setSessions_id(res.data.data[0].session_id)
        sessionStorage.setItem(
          "currentSessionData",
          JSON.stringify(res.data.data[0])
        );
        global.screenshot_timestamp = data.screenshot_timestamp;
        global.sessionname = data.sessionname;
        global.deviceid = data.device_id;
        global.userid = data.user_id;
        global.appname = data.app_name;
        global.sessionid = data.session_id;
        global.gpu_usage_time = data.gpu_usage_time;

      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log("error", error)
          SetSecondsessiondata([]);
        }
      });
  }

  function singleSessionItem(e, data, id) {

    axios
      .get("/sessionDetails", {
        params: {
          DeviceId: device,
          appName: appName,
          userId: uid,
          sessionId: sessions_id,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((res) => {
        SetSecondsessiondata(res.data.data[0]);
        setDevices(res.data.data[0].device)
        setAppName(res.data.data[0].appName)
        setSessions_id(res.data.data[0].session_id)
        sessiondressdetails(data.session_id);
        sessionStorage.setItem(
          "currentSessionData",
          JSON.stringify(res.data.data[0])
        );
        global.screenshot_timestamp = data.screenshot_timestamp;
        global.sessionname = data.sessionname;
        global.deviceid = data.device_id;
        global.userid = data.user_id;
        global.appname = data.app_name;
        global.sessionid = data.session_id;
        global.gpu_usage_time = data.gpu_usage_time;

      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.log("error", error)
          SetSecondsessiondata([]);
        }
      });
  }

  const getdataid = (xaxis, yaxis) => {
    screenshot(xaxis);
  };

  const [timeStamp, setTimeStamp] = useState("");
  const [timeNull, setTimenull] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  function screenshot(xaxis) {
    const newArray = [];
    if (Secondsessiondata?.screenshot_timestamp) {
      newArray.push(Secondsessiondata?.screenshot_timestamp);
    }

    if (newArray.length !== 0) {
      const value = Secondsessiondata?.gpu_usage_time.indexOf(xaxis);
      const img = `https://gamemon-service.indiumsoft.com/getImage/?filename=${Secondsessiondata?.session_id}-${Secondsessiondata?.screenshot_timestamp[value]}.png`;
      console.log("images", img);
      setTimenull(Secondsessiondata?.screenshot_timestamp[value]);
      setTimeStamp(img);
      setIsLoading(true);
    }
  }

  const handleImageLoads = () => {
    setIsLoading(false);
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setImageError(true);
    setImageLoaded(false);
  };


  const handleClickGraph = (graphId) => {
    setSelectedGraph(graphId);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const path = url.search;
    const lastSlashIndex = path.lastIndexOf("=");
    const secondLastSlashIndex = path.lastIndexOf("=", lastSlashIndex - 1);
    const epoch = path.substring(lastSlashIndex + 1);
    const ampersandIndex1 = path.indexOf("&", secondLastSlashIndex);
    const sessionId = path.substring(secondLastSlashIndex + 1, ampersandIndex1);
    // console.log("User ID", epoch);
    // console.log("Session Id  ", sessionId);
    setEpoch_time(epoch)
    setSessions_id(sessionId)
    // singleSessionItem2();
  }, []);

  useEffect(() => {
    if (epoch_time && sessions_id) {
      singleSessionItem2();
    }
  }, [sessions_id]);

  const renderGraphComponent = () => {
    const data = {
      device,
      appName,
      sessions_id,
      uid,
    };
    console.log("passing fata", data)
    switch (selectedGraph) {
      case 1:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricCpuMain data={data} senddata1={getdataid} />
          </div>
        );
      case 2:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricGpuMain data={data} senddata1={getdataid} />
          </div>
        );
      case 3:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricMemory data={data} senddata1={getdataid} />
          </div>
        );
      case 4:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricFpsMain data={data} senddata1={getdataid} />
          </div>
        );
      case 5:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricDownloadData data={data} senddata1={getdataid} />
          </div>
        );
      case 6:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricUploadData data={data} senddata1={getdataid} />
          </div>
        );
      case 7:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricPeakMemory data={data} senddata1={getdataid} />
          </div>
        );
      case 8:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricFpsStability data={data} senddata1={getdataid} />
          </div>
        );
      case 9:
        return (
          <div style={{ marginTop: "5vh" }}>
            <MetricAppPower data={data} senddata1={getdataid} />
          </div>
        );
      default:
        return null;
    }
  };

  const handleBatteryClick = () => {
    const errorMessage = "Oops! No stats available!";
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <div style={{ width: "70%", marginTop: "8%" }}>
      <h3 style={{ textAlign: "left", marginLeft: "4%", color: currentTheme === "dark" ? "white" : "black", }}>Session Details</h3>
      <br />
      <div style={{ marginLeft: "-18vw" }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div
              style={{
                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              }}
              className="sessionContainers"
            >
              <div
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                }}
                className="icon-containers"
              >
                <PhoneAndroidIcon className="icons" style={{ marginLeft: "-0.5vw" }} />
              </div>
              <div style={{ width: "80%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "white" : "#293134",
                        // textTransform:"capitalize",
                      }}
                      title={Secondsessiondata?.app_name}
                      className="container-fonts"
                    >
                      {/* {Secondsessiondata?.app_name} */}
                      {Secondsessiondata?.app_name}
                      {/* {Secondsessiondata?.app_name && (Secondsessiondata.app_name.includes('.') ? Secondsessiondata.app_name.split('.').pop().slice(-20) : Secondsessiondata.app_name)} */}
                    </div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                      }}
                      className="version-fonts2"
                    >
                      <span>Version: {""}</span>
                      <span style={{ fontWeight: "600" }}>
                        {Secondsessiondata?.version_name}
                      </span>
                    </div>
                    <div
                      style={{
                        color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                      }}
                      className="version-fonts2"
                    >
                      <span>Last Updated: {""}</span>
                      <span style={{ fontWeight: "600" }}>
                        {Secondsessiondata?.created_at}
                      </span>
                    </div>
                  </div>
                  <div>
                    <>
                      <MoreVertIcon
                        onClick={handleClick}
                        titleAccess="More"
                        size="small"
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        sx={{
                          fontSize: 20,
                          color: "#4779EF",
                          cursor: "pointer",
                          marginRight: "-10px"
                        }}
                      />
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            backgroundColor:
                              currentTheme === "dark" ? "#363C43" : "#FFFFFF",
                            color:
                              currentTheme === "dark" ? "#FFFFFF" : "#000000",
                            overflow: "visible",
                            filter:
                              "drop-shadow(0px 2px 8px rgba(0,0,0,0.32)",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "& .MuiMenuItem-root": {
                              fontSize: "14px",
                              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                              fontWeight: "400",
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                              backgroundColor:
                                currentTheme === "dark"
                                  ? "#363C43"
                                  : "#FFFFFF",
                              "& .MuiMenuItem-root.Mui-selected": {
                                backgroundColor: "yellow",
                              },
                              "& .MuiMenuItem-root:hover": {
                                backgroundColor: "pink",
                              },
                              "& .MuiMenuItem-root.Mui-selected:hover": {
                                backgroundColor: "red",
                              },
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            if (Secondsessiondata?.session_id !== "") {

                              window.open(
                                `https://gamemon-service.indiumsoft.com/getReport?sessionID=${Secondsessiondata?.session_id}`
                              );
                            }
                          }}
                          style={{
                            fontSize: "14px",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                            fontWeight: "400",
                          }}
                        >
                          Download Report
                        </MenuItem>
                        <MenuItem
                          onClick={opener}
                          style={{
                            fontSize: "14px",
                            fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
                            fontWeight: "400",
                          }}
                        >
                          Download log File
                        </MenuItem>
                      </Menu>
                    </>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                    }}
                    className="version-fonts3"
                  >
                    <span>Device:{""}</span>
                    <span
                      style={{
                        fontWeight: "600",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      title={Secondsessiondata?.device_name}
                    >
                      {/* {Secondsessiondata?.device_name} */}
                      {""} {Secondsessiondata?.device_name
                        ? Secondsessiondata?.device_name.length > 10
                          ? Secondsessiondata?.device_name.substring(0, 8) +
                          "..."
                          : Secondsessiondata?.device_name
                        : ""}
                    </span>
                  </div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                      marginLeft: "-0.5px",
                    }}
                    className="version-fonts3"
                  >
                    <span>OS version:{""}</span>
                    <span style={{ fontWeight: "600" }}>
                      {""} {Secondsessiondata?.os_version}
                    </span>
                  </div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#FFFFFF" : "#818E94",
                      marginRight: "10px",
                    }}
                    className="version-fonts3"
                  >
                    <span>GPU:{""}</span>
                    <span
                      style={{
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                      }}
                      title={
                        Secondsessiondata?.gpu_vendor?.includes(",")
                          ? Secondsessiondata?.gpu_vendor
                            .split(",", 2)
                            .join(",")
                          : Secondsessiondata?.gpu_vendor
                      }
                    >
                      {""} {Secondsessiondata?.gpu_vendor?.includes(",")
                        ? Secondsessiondata?.gpu_vendor
                          .split(",", 2)
                          .join(",")
                        : Secondsessiondata?.gpu_vendor}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div
              style={{
                backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
              }}
              className="user-containers"
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                    }}
                    className="user-fontss"
                  >
                    User
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
                    }}
                    title={Secondsessiondata?.user_name}
                    className="user-fontss"
                  >
                    {Secondsessiondata?.user_name}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                    }}
                    className="user-fontss"
                  >
                    Session Title
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      textTransform: "capitalize",
                    }}
                    title={Secondsessiondata?.sessionname}
                    className="user-fontss"
                  >
                    {Secondsessiondata?.sessionname ? (
                      <>
                        {Secondsessiondata?.sessionname}

                      </>
                    ) : null}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: currentTheme === "dark" ? "#C5CACF" : "#818E94",
                    }}
                    className="user-fontss"
                  >
                    Session Duration
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      color: currentTheme === "dark" ? "#FFFFFF" : "#262626",
                    }}
                    className="user-fontss"
                  >
                    {Secondsessiondata?.total_duration !== undefined && (
                      <span>
                        {typeof Secondsessiondata.total_duration === 'number' ? (
                          formatTime(Secondsessiondata.total_duration)
                        ) : (
                          !isNaN(Number(Secondsessiondata.total_duration)) ? (
                            formatTime(Number(Secondsessiondata.total_duration))
                          ) : (
                            Secondsessiondata.total_duration
                          )
                        )}
                      </span>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                backgroundColor:
                  currentTheme === "dark" ? "#292E33" : "white",
                color: currentTheme === "dark" ? "white" : "black",
                overflowY: "auto",
                overflowX: "hidden",
                scrollBehavior: "smooth",
                // marginTop:"-1%",
                marginLeft: "15%"
              }}
              className="sessionNote-Containers"
            >
              <h3 className="font-weight" style={{ margin: "5%" }}>Session Notes</h3>
              {notes !== null && (
                <div>
                  <div>
                    {notes &&
                      notes?.map((note, index) => (
                        <div key={index}>
                          <div style={{ marginLeft: "5%", marginTop: "5%" }}>
                            <span style={{ fontWeight: "bold" }}>{index + 1}) Title: </span><span style={{
                              fontWeight: "600",
                              textTransform: "capitalize",
                            }}> {note?.session_notes_title}</span>

                          </div>
                          <div style={{ marginLeft: "5%", marginTop: "5px" }}>
                            <span style={{ fontWeight: "bold", marginLeft: "10%", }}>Description : </span>
                            <p
                              style={{
                                marginLeft: "12%",
                                textIndent: "-8%",
                                paddingLeft: "8%",
                                fontSize: "13px",
                                fontWeight: "400",
                                // textTransform: "capitalize",
                              }}
                            >
                              &bull; {note?.session_notes}
                            </p>

                          </div>
                          {/* <button
                              style={{
                                backgroundColor:
                                  currentTheme === "dark" ? "#292E33" : "white",
                                color:
                                  currentTheme === "dark" ? "white" : "black",
                                textTransform: "capitalize",
                                height: "30px",
                                width: "14vw",
                                cursor: "pointer",
                                // padding: '10px',
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                position: "relative",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {note.session_notes_title}
                            </button> */}

                          {/* default dialog to trigger others */}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>

        {/* Metric Grid */}
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "98%",
              marginLeft: "20vw",
            }}
            className="metric-gridss"
          >
            {/* First Row */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.cpu_average_usage !== undefined
                      ? Math.min(Secondsessiondata?.cpu_average_usage, 100)
                      : 0
                  }
                  sharingwidth={"max"}
                  text="CPU Usage"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 1}
                  onClick={() => handleClickGraph(1)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.gpu_average_usage !== undefined
                      ? Secondsessiondata?.gpu_average_usage
                      : 0
                  }
                  sharingwidth={"max"}
                  text="GPU Usage"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 2}
                  onClick={() => handleClickGraph(2)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.memory_average_usage !== undefined
                      ? Secondsessiondata?.memory_average_usage
                      : 0
                  }
                  sharingwidth={"max"}
                  text="Average Memory Usage"
                  unit="MB"
                  max={parseFloat(Secondsessiondata?.ram_size) * 1024}
                  maxRef={
                    Secondsessiondata?.ram_size == null ||
                      Secondsessiondata?.ram_size == 0
                      ? "NA"
                      : Secondsessiondata?.ram_size
                  }
                  selected={selectedGraph === 3}
                  onClick={() => handleClickGraph(3)}
                />
              </div>
            </div>
            {/* Second Row */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.average_fps_value !== undefined
                      ? Secondsessiondata?.average_fps_value
                      : 0
                  }
                  sharingwidth={"max"}
                  text="FPS"
                  unit=""
                  max={100}
                  selected={selectedGraph === 4}
                  onClick={() => handleClickGraph(4)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.download_data_usage_average !== undefined
                      ? Secondsessiondata.download_data_usage_average > 1024
                        ? (
                          parseFloat(
                            Secondsessiondata.download_data_usage_average
                          ) / 1024
                        ).toFixed(2)
                        : parseFloat(
                          Secondsessiondata.download_data_usage_average
                        ).toFixed(2)
                      : 0
                  }
                  sharingwidth={"max"}
                  text="Total Data Downloaded"
                  unit={
                    Secondsessiondata?.download_data_usage_average !==
                      undefined &&
                      Secondsessiondata.download_data_usage_average > 1024
                      ? "MiB"
                      : "KiB"
                  }
                  max={
                    Secondsessiondata?.download_data_usage_average !==
                      undefined &&
                      Secondsessiondata.download_data_usage_average > 1024
                      ? 100
                      : 1024
                  }
                  selected={selectedGraph === 5}
                  onClick={() => handleClickGraph(5)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.upload_data_usage_average !== undefined
                      ? Secondsessiondata.upload_data_usage_average > 1024
                        ? (
                          parseFloat(
                            Secondsessiondata.upload_data_usage_average
                          ) / 1024
                        ).toFixed(2)
                        : parseFloat(
                          Secondsessiondata.upload_data_usage_average
                        ).toFixed(2)
                      : 0
                  }
                  sharingwidth={"max"}
                  text="Total Data Uploaded"
                  unit={
                    Secondsessiondata?.upload_data_usage_average !==
                      undefined &&
                      Secondsessiondata.upload_data_usage_average > 1024
                      ? "MiB"
                      : "KiB"
                  }
                  max={
                    Secondsessiondata?.upload_data_usage_average !==
                      undefined &&
                      Secondsessiondata.upload_data_usage_average > 1024
                      ? 100
                      : 1024
                  }
                  selected={selectedGraph === 6}
                  onClick={() => handleClickGraph(6)}
                />
              </div>
            </div>
            {/* Third Row */}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.peak_memory_value !== undefined
                      ? Secondsessiondata?.peak_memory_value
                      : 0
                  }
                  sharingwidth={"max"}
                  text="Average Peak Memory"
                  unit="MB"
                  max={parseFloat(Secondsessiondata?.ram_size) * 1024}
                  maxRef={
                    Secondsessiondata?.ram_size == null ||
                      Secondsessiondata?.ram_size == 0
                      ? "NA"
                      : Secondsessiondata?.ram_size
                  }
                  selected={selectedGraph === 7}
                  onClick={() => handleClickGraph(7)}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value={
                    Secondsessiondata?.fps_stability !== undefined &&
                      !isNaN(Secondsessiondata?.fps_stability)
                      ? Secondsessiondata?.fps_stability
                      : 0
                  }
                  sharingwidth={"max"}
                  text="FPS Stability"
                  unit="%"
                  max={100}
                  selected={selectedGraph === 8}
                  onClick={handleBatteryClick}
                />
              </div>
              <div style={{ flex: 1 }}>
                <MetricUsage
                  value="0"
                  sharingwidth={"max"}
                  text="Battery Usage"
                  unit="%"
                  max={100}
                  onClick={handleBatteryClick}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: "21vw",
              display: "grid",
              // gridTemplateColumns: "repeat(3, 1fr)",
              // gridTemplateRows: "repeat(2, 1fr)",
              gap: "1%",
              width: "100%",
              height: "100%",
              marginTop: "1rem",
            }}
          >
            <div style={{ gridRow: "1 / span 1" }}>
              <div
                style={{
                  width: "20vw",
                  height: "300px",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px #0000000A",
                  overflow: "hidden",
                  boxSizing: "border-box",
                }}
              >
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    fontSize: "14px",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Screen Flow
                </p>
                <hr />
                <div
                  style={{ float: "right", margin: "20px", marginTop: "10px" }}
                  title="Explore"
                >
                  {timeNull !== null ? (
                    <TravelExploreIcon
                      style={{
                        fontSize: "2rem",
                        color: currentTheme === "dark" ? "white" : "black",
                        cursor: "pointer",
                      }}
                      onClick={handleZoomIconClick}
                    />
                  ) : null}
                </div>
                <div
                  style={{
                    // display: "flex",
                    // flexDirection: "row",
                    // justifyContent: "space-around",
                    overflowClipMargin: "content-box",
                    overflow: "clip",
                    height: "100%",
                    maxWidth: "100%",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  {isLoading && (
                    <div
                      style={{
                        fontSize: "12px",
                        color: currentTheme === "dark" ? "white" : "black",
                      }}
                      title="fetching the image from server"
                    >
                      Fetching...
                    </div>
                  )}
                  {imageError}
                  <img
                    style={{
                      // display: "block",
                      // marginTop: "-10px",
                      marginBottom: "1%",
                      borderRadius: "5px",
                      maxWidth: "100%",
                      height: "90%",
                      boxSizing: "border-box",
                      color: currentTheme === "dark" ? "white" : "black",
                      fontSize: "12px",
                    }}
                    src={timeStamp}
                    onLoad={handleImageLoads}
                    onError={handleImageError}
                    alt={
                      timeNull !== null
                        ? "Image Not Found!"
                        : "Oops! Pick a Image!"
                    }
                  />
                </div>
              </div>

              {/* Modal */}
              {isModalOpen && (
                <div
                  style={{
                    position: "fixed",
                    top: 40,
                    left: -5,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backdropFilter: "blur(5px)",
                    zIndex: 999,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                      cursor: "zoom-in",
                    }}
                  >
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          style={{
                            margin: "0.5%",
                            height: isPortrait ? "250px" : "440px",
                            width: "220px",
                            color: currentTheme === "dark" ? "white" : "black",
                            fontSize: "12px",
                            borderRadius: "5px",
                            transform: `rotate(${rotationAngle}deg)`,
                            transition: "0.5s ease-in-out",
                            textAlign: "center",
                          }}
                          src={selectedImage}
                          alt=""
                        />
                      </TransformComponent>
                    </TransformWrapper>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        position: "absolute",
                        top: -23,
                        left: 130,
                        padding: "10px",
                      }}
                    >
                      <CloseIcon
                        CloseIcon
                        style={{
                          display: "flex",
                          justifyContent: "top",
                          alignItems: "top",
                          fontSize: "2rem",
                          color: currentTheme === "dark" ? "white" : "black",
                          cursor: "pointer",
                          // marginLeft: "-50px",
                          // marginTop: "-500px",
                        }}
                        onClick={closeModal}
                      ></CloseIcon>
                    </div>
                    <br />
                    <ScreenRotationIcon
                      style={{
                        fontSize: "2rem",
                        color: currentTheme === "dark" ? "white" : "black",
                        cursor: "pointer",
                        marginTop: "5px",
                      }}
                      onClick={rotateImage}
                    />
                  </div>
                </div>
              )}
            </div>
            <div style={{ gridRow: "1 / span 1" }}>
              <div
                style={{
                  width: "60vw",
                  height: "300px",
                  backgroundColor:
                    currentTheme === "dark" ? "#292E33" : "white",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 6px #0000000A",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "2%"
                }}
              >
                <p
                  style={{
                    color: currentTheme === "dark" ? "white" : "black",
                    fontSize: "14px",
                    padding: "5px",
                    fontWeight: "bold",
                    // textTransform: "capitalize",
                  }}
                >
                  Selected Graph
                </p>
                <hr />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowX: "auto",
                    overflowY: "hidden",
                    cursor: "pointer",
                    marginTop: "5vh",
                  }}
                  className="ScrollBars"
                >
                  {renderGraphComponent()}
                </div>
              </div>
            </div>
          </div>
          {/* Render Graph Component */}
          {/* <div currentTheme={currentTheme} style={{ marginLeft: "20vw" }}>
            {renderGraphComponent()}
          </div> */}
        </div>
      </div>
      <ToastContainer
        toastStyle={{
          marginTop: "4rem",
          borderRadius: "10px",
          backgroundColor: "white",
          alignContent: "center",
          height: 50,
          margin: 10,
          width: 300,
          closeOnClick: true,
          textAlign: "center",
        }}
        closeButton={false}
        hideProgressBar={false}
        autoClose={2000}
        position="top-center"
        className="toast-container"
        toastClassName="dark-toast"
      />
    </div>
  );
}
