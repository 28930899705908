// VideoSection.js
import React, { useState } from 'react';
import VideoPlayer from './VideoPlayer';
import './VideoSection.css';

const VideoSection = ({ onProgress, timestamp,videoUrl }) => {
  return (
    <div className="player-wrapper">
      <VideoPlayer timestamp={timestamp} onProgress={onProgress} videoUrl={videoUrl} />
    </div>
  );
};

export default VideoSection;
