import React from 'react';
import AvgMemoryGraph from './AvgMemoryGraph';
import CpuGraph from './CpuGraph';
import DownloadGraph from './DownloadGraph';
import FpsGraph from './FpsGraph';
import FpsStabilityGraph from './FpsStabilityGraph';
import GpuGraph from './GpuGraph';
import PeakMemoryGraph from './PeakMemoryGraph';
import UploadGraph from './UploadGraph';

const GraphSection = ({ currentTime, onPointClick, selectedVideoGraph }) => {
  const renderGraph = () => {
    switch (selectedVideoGraph) {
      case 1:
        return <CpuGraph currentTime={currentTime} onPointClick={onPointClick} />;
      case 2:
        return <GpuGraph currentTime={currentTime} onPointClick={onPointClick} />;
      case 3:
        return <AvgMemoryGraph currentTime={currentTime} onPointClick={onPointClick} />;
      case 4:
        return <FpsGraph currentTime={currentTime} onPointClick={onPointClick} />;
      case 5:
        return <DownloadGraph currentTime={currentTime} onPointClick={onPointClick} />;
      case 6:
        return <UploadGraph currentTime={currentTime} onPointClick={onPointClick} />;
      case 7:
        return <PeakMemoryGraph currentTime={currentTime} onPointClick={onPointClick} />;
      case 8:
        return <FpsStabilityGraph currentTime={currentTime} onPointClick={onPointClick} />;
      default:
        return null;
    }
  };

  return <div className="chart-wrapper">{renderGraph()}</div>;
};

export default GraphSection;
