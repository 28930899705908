import React, { useContext, useState, useRef } from "react";
import { ThemePreferenceContext } from "../../../../index";
import UserProfile from "../../../../asset/User Profile.png";
import AuthContext from "../../../../hooks/useAuth";

// Separate CSS styles/*  */
import "./Profile.css"; // Import your CSS file here

function Profile() {
  const auth = useContext(AuthContext);
  const { currentTheme, setCurrentTheme } = useContext(ThemePreferenceContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const inputRef = useRef(null);
  const name = auth.name;
  const role = auth.role;
  const email = auth.email;
  const organization_name = auth.organization_name;
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <main style={{ display: "flex" }}>
        <div
          className="containerStyle1"
          style={{
            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
          }}
        >
          <div>
            <div>
              <img
                src={UserProfile}
                alt="Default Profile"
                className="imageStyle"
              />
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
                ref={inputRef}
              />
            </div>
            <div
              className="LabelStyle"
              style={{ color: currentTheme === "dark" ? "white" : "black" }}
            >
              <span style={{ fontWeight: "bold" }}>User Name</span>
              <br />
              <span
                className="InputOfLabel"
                style={{
                  color: currentTheme === "dark" ? "#99999F" : "#818E94",
                }} title={name}
              > 
                {name}
              </span>
            </div>
          </div>
          <div>
            <div
              className="Heading"
              style={{ color: currentTheme === "dark" ? "white" : "black" }}
            >
              Profile
            </div>
          </div>

          <div className="row-container">
            <div
              className="LabelStyle1"
              style={{ color: currentTheme === "dark" ? "#99999F" : "#818E94" }}
            >
              Name
              <br />
              <div
                className="InputOfLabel1"
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                  textTransform: "capitalize",
                }} title={name}
              >
                {name}
              </div>
            </div>
            <div
              className="LabelStyle1"
              style={{ color: currentTheme === "dark" ? "#99999F" : "#818E94" }}
            >
              Email
              <br />
              <div
                className="InputOfLabel1"
                style={{ color: currentTheme === "dark" ? "white" : "black" }}  title={email}
              > 
                {email}
              </div>
            </div>
            <div
              className="LabelStyle1"
              style={{ color: currentTheme === "dark" ? "#99999F" : "#818E94",marginLeft:"5%" }}
            >
              Role
              <br />
              <div
                className="InputOfLabel1"
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                  textTransform: "capitalize",
                }}  title={role}
              >
                 {role === "super_admin" ? "Super Admin" : role}
              </div>
            </div>
            <div
              className="LabelStyle1"
              style={{ color: currentTheme === "dark" ? "#99999F" : "#818E94" }}
            >
              Company
              <br />
              <div
                className="InputOfLabel1"
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                  textTransform: "capitalize",
                }}  
                // title={organization_name}
              >
                {/* {organization_name} */}
                Indium Software Pvt Ltd
              </div>
            </div>
          </div>

          <div className="row-container">
           
           
          </div>
        </div>

        {/* <div
          className="containerStyle2"
          style={{
            backgroundColor: currentTheme === "dark" ? "#292E33" : "white",
          }}
        >
          <div
            className="LicenseHeading"
            style={{ color: currentTheme === "dark" ? "white" : "black" }}
          >
            <span style={{ fontWeight: "bold" }}>License Information</span>
          </div>
          <div className="column-container">
            <div
              className="LicenseLabel"
              style={{ color: currentTheme === "dark" ? "#99999F" : "#818E94" }}
            >
              Company Name
              <br />
              <div
                className="LicenseInput"
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                  textTransform: "capitalize",
                }}
              >
                Indium Software pvt ltd
              </div>
            </div>
            <div
              className="LicenseLabel"
              style={{ color: currentTheme === "dark" ? "#99999F" : "#818E94" }}
            >
              Time Allowance
              <br />
              <div
                className="LicenseInput"
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                  textTransform: "capitalize",
                }}
              >
                1000 hours
              </div>
            </div>
            <div
              className="LicenseLabel"
              style={{ color: currentTheme === "dark" ? "#99999F" : "#818E94" }}
            >
              License Type
              <br />
              <div
                className="LicenseInput"
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                  textTransform: "capitalize",
                }}
              >
                Time based
              </div>
            </div>
            <div
              className="LicenseLabel"
              style={{ color: currentTheme === "dark" ? "#99999F" : "#818E94" }}
            >
              License Term
              <br />
              <div
                className="LicenseInput"
                style={{
                  color: currentTheme === "dark" ? "white" : "black",
                  textTransform: "capitalize",
                }}
              >
                10/09/2023-10/10/2024
              </div>
            </div>
          </div>
        </div> */}
      </main>
    </>
  );
}

export default Profile;
