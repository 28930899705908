import React, { useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ timestamp, onProgress,videoUrl }) => {
  const playerRef = useRef(null);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(parseFloat(timestamp*2));
    }
  }, [timestamp]);
  
  return (
    <ReactPlayer
      ref={playerRef}
      url={videoUrl} 
      controls
      width="55%"
      height="10%"
      onProgress={onProgress}
      style={{borderRadius:"5px"}}
    />
  );
};

export default VideoPlayer;
