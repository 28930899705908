import React, { useContext } from "react";
import UserTabLayout from "../../../TabLayout/UserTabLayout";
import App3 from "./OrgActive";
import App4 from "./OrgInactive";
import { ThemePreferenceContext} from "../../../../index";

function SuperAdminwrapper() {
  const { currentTheme } = useContext(ThemePreferenceContext);
  const menuItems = [
    {
      name: "active users",
      label: "Active Organizations",
      content: <App3 />,
    },
    {
      name: "inactive users",
      label: "Inactive Organizations",
      content: <App4 />,
    },
  ];

  return (
    <div data-theme={currentTheme} style={{ marginTop: "2rem" }}>
      <UserTabLayout
        menuItems={menuItems}
        defaultTab="active users"
      ></UserTabLayout>
    </div>
  );
}

export default SuperAdminwrapper;
